/*
Breadcrumb

Markup:
<div class="breadcrumbWrapper">
  <div class="breadcrumb">
    <a href="#">Level 1</a>
    <i class="icon icon-angle-right"></i>
    <a href="#">Level 2</a>
    <i class="icon icon-angle-right"></i>
    <span class="current">Current</span>
  </div>
  <a class="breadcrumbBackbutton" href="#"><i class="icon icon-angle-left"></i>Back</a>
</div>

Styleguide Components.Breadcrumb
*/

.breadcrumb {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border-radius: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  line-height: 18px;

  @media (max-width: $breadcrumbBreakScrollable){
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow: hidden;
    overflow-x: auto;
  }

  i {
    margin: 0 5px;
  }

  a {
    i {
      display: block;
      margin: 0;
      font-size: 1.2em;
    }
  }

  .current {
    @if ($breadcrumbLinkColorCurrent != $breadcrumbLinkColor) {
      color: $breadcrumbLinkColorCurrent;

      a {
        color: $breadcrumbLinkColorCurrent;
      }
    }
    @if ($breadcrumbLinkFontWeightCurrent != $normal) {
      font-weight: $breadcrumbLinkFontWeightCurrent;
    }
  }
}

.breadcrumbBackbutton {
  color: $breadcrumbLinkColor;
  display: inline-flex;
  align-items: center;

  &:hover {
    color: $breadcrumbLinkColorHover;
    text-decoration: none;
  }

  i {
    margin-right: 4px;
  }

  @media (max-width: $breadcrumbBreakScrollable){
    margin-top: 10px;
  }
}

.breadcrumbWrapper {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: $breadcrumbFontSize;
  line-height: 1;
  &.breadcrumbInBanner {
    position: absolute;
  }

  @media (min-width: $breadcrumbBreakScrollable + 1) {
    padding-bottom: 40px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @if ($breadcrumbLinkColor != $text-base) {
    color: $breadcrumbLinkColor;
  }

  a {
    color: $breadcrumbLinkColor;
    text-decoration: none;

    &:hover {
      color: $breadcrumbLinkColorHover;
      text-decoration: none;
    }
  }
}

body {
  &.pg_shop {
    &.card {
      .breadcrumbContainer {
        background: $soft-gray;
      }
    }
  }
}

@media (min-width: $breadcrumbBreakScrollableMin){
  body {
    &.pg_begleitdokumente, &.pg_belege {
      .breadcrumb {
        .current {
          font-size: 3.6rem;
        }
      }
    }
  }
}

