/*
Mainnav

Markup:
<nav class="mainnav">
  <ul>
    <li class="is-active">
      <a href="#">Level 1</a>
    </li>
    <li>
      <a href="#">Level 1</a>
    </li>
    <li>
      <a href="#">Level 1</a>
    </li>
  </ul>
  <span class="mainnav__seperator"></span>
  <ul>
    <li>
      <a href="#">Level 1</a>
    </li>
    <li>
      <a href="#">Level 1</a>
    </li>
    <li>
      <a href="#">Level 1</a>
    </li>
  </ul>
</nav>

Styleguide Navigations.Mainnav
*/

.mainnav {
  display: flex;
  align-items: center;

  &__seperator {
    width: 1px;
    height: 27px;
    background: $gray-base;
    display: block;
    margin-left: $mainnavSpaceBetweenLinks;
    margin-right: $mainnavSpaceBetweenLinks;

    @media (min-width: $screen-lg) {
      margin-left: $mainnavSpaceBetweenLinksLG;
      margin-right: $mainnavSpaceBetweenLinksLG;
    }
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  a {
    color: $mainnavColorLink;
    text-decoration: none;
  }

  li {
    &:hover, &:focus, &:active {
      > a {
        color: $mainnavColorLinkHover;
      }
    }

    &.is-active, &.is-active-tree {
      > a {
        color: $mainnavLinkActiveColor;
      }

      &:hover, &:focus, &:active {
        > a {
          color: $mainnavColorLinkHover;

        }
      }
    }
  }

  > ul {
    display: flex;
    margin-left: calc(-1 * $mainnavSpaceBetweenLinks / 2);
    margin-right: calc(-1 * $mainnavSpaceBetweenLinks / 2);
    font-size: $mainnavFontSize;

    @media (min-width: $screen-lg) {
      margin-left: calc(-1 * $mainnavSpaceBetweenLinksLG / 2);
      margin-right: calc(-1 * $mainnavSpaceBetweenLinksLG / 2);
    }

    @media (min-width: $screen-xlg) {
      font-size: $mainnavFontSizeXLG;
    }

    > li {
      padding-left: calc($mainnavSpaceBetweenLinks / 2);
      padding-right: calc($mainnavSpaceBetweenLinks / 2);

      @media (min-width: $screen-lg) {
        padding-left: calc($mainnavSpaceBetweenLinksLG / 2);
        padding-right: calc($mainnavSpaceBetweenLinksLG / 2);
      }

      > a {
        font-weight: $mainnavFontWeightLevel1;
        position: relative;
        display: block;
      }
    }
  }

  > ul > li.hoverintent {
    .megaMenu,
    > ul {
      display: block;
    }
  }
}


.mainnav {
  .hoverMenu {
    .container {
      max-width: 1920px;
    }
  }
}