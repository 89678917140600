/*
Icons

Markup:
<i class="icon icon-name"></i>

<div class="kss-icons">

<i class='icon icon-academy'></i> <span>icon icon-academy</span>

<i class='icon icon-action'></i> <span>icon icon-action</span>

<i class='icon icon-address'></i> <span>icon icon-address</span>

<i class='icon icon-addressbook'></i> <span>icon icon-addressbook</span>

<i class='icon icon-altersvorsorge'></i> <span>icon icon-altersvorsorge</span>

<i class='icon icon-angle-down'></i> <span>icon icon-angle-down</span>

<i class='icon icon-angle-left'></i> <span>icon icon-angle-left</span>

<i class='icon icon-angle-right'></i> <span>icon icon-angle-right</span>

<i class='icon icon-angle-up'></i> <span>icon icon-angle-up</span>

<i class='icon icon-arrow-down'></i> <span>icon icon-arrow-down</span>

<i class='icon icon-arrow-left'></i> <span>icon icon-arrow-left</span>

<i class='icon icon-arrow-right'></i> <span>icon icon-arrow-right</span>

<i class='icon icon-arrow-up'></i> <span>icon icon-arrow-up</span>

<i class='icon icon-basket'></i> <span>icon icon-basket</span>

<i class='icon icon-chart'></i> <span>icon icon-chart</span>

<i class='icon icon-chat'></i> <span>icon icon-chat</span>

<i class='icon icon-check-circle'></i> <span>icon icon-check-circle</span>

<i class='icon icon-check'></i> <span>icon icon-check</span>

<i class='icon icon-circle-arrow-right'></i> <span>icon icon-circle-arrow-right</span>

<i class='icon icon-circle-close'></i> <span>icon icon-circle-close</span>

<i class='icon icon-clock'></i> <span>icon icon-clock</span>

<i class='icon icon-close'></i> <span>icon icon-close</span>

<i class='icon icon-columns'></i> <span>icon icon-columns</span>

<i class='icon icon-copy'></i> <span>icon icon-copy</span>

<i class='icon icon-date'></i> <span>icon icon-date</span>

<i class='icon icon-document-archive'></i> <span>icon icon-document-archive</span>

<i class='icon icon-download'></i> <span>icon icon-download</span>

<i class='icon icon-drag-drop'></i> <span>icon icon-drag-drop</span>

<i class='icon icon-edit'></i> <span>icon icon-edit</span>

<i class='icon icon-exclamation-circle'></i> <span>icon icon-exclamation-circle</span>

<i class='icon icon-exclamation'></i> <span>icon icon-exclamation</span>

<i class='icon icon-eye'></i> <span>icon icon-eye</span>

<i class='icon icon-facebook'></i> <span>icon icon-facebook</span>

<i class='icon icon-file-belege'></i> <span>icon icon-file-belege</span>

<i class='icon icon-file-doc'></i> <span>icon icon-file-doc</span>

<i class='icon icon-file-document'></i> <span>icon icon-file-document</span>

<i class='icon icon-file-image'></i> <span>icon icon-file-image</span>

<i class='icon icon-file-pdf'></i> <span>icon icon-file-pdf</span>

<i class='icon icon-file-powerpoint'></i> <span>icon icon-file-powerpoint</span>

<i class='icon icon-file-video'></i> <span>icon icon-file-video</span>

<i class='icon icon-file-xls'></i> <span>icon icon-file-xls</span>

<i class='icon icon-filter'></i> <span>icon icon-filter</span>

<i class='icon icon-fitness'></i> <span>icon icon-fitness</span>

<i class='icon icon-gift'></i> <span>icon icon-gift</span>

<i class='icon icon-global'></i> <span>icon icon-global</span>

<i class='icon icon-haende-herz'></i> <span>icon icon-haende-herz</span>

<i class='icon icon-happy-kind'></i> <span>icon icon-happy-kind</span>

<i class='icon icon-heart-filled'></i> <span>icon icon-heart-filled</span>

<i class='icon icon-heart'></i> <span>icon icon-heart</span>

<i class='icon icon-home'></i> <span>icon icon-home</span>

<i class='icon icon-info'></i> <span>icon icon-info</span>

<i class='icon icon-instagram'></i> <span>icon icon-instagram</span>

<i class='icon icon-invoice'></i> <span>icon icon-invoice</span>

<i class='icon icon-jobrad'></i> <span>icon icon-jobrad</span>

<i class='icon icon-language'></i> <span>icon icon-language</span>

<i class='icon icon-linkedin'></i> <span>icon icon-linkedin</span>

<i class='icon icon-list'></i> <span>icon icon-list</span>

<i class='icon icon-loading'></i> <span>icon icon-loading</span>

<i class='icon icon-lock'></i> <span>icon icon-lock</span>

<i class='icon icon-logged'></i> <span>icon icon-logged</span>

<i class='icon icon-login'></i> <span>icon icon-login</span>

<i class='icon icon-logout'></i> <span>icon icon-logout</span>

<i class='icon icon-mail'></i> <span>icon icon-mail</span>

<i class='icon icon-map-pin'></i> <span>icon icon-map-pin</span>

<i class='icon icon-marketing'></i> <span>icon icon-marketing</span>

<i class='icon icon-massgeschneidert'></i> <span>icon icon-massgeschneidert</span>

<i class='icon icon-minus'></i> <span>icon icon-minus</span>

<i class='icon icon-nachhaltig'></i> <span>icon icon-nachhaltig</span>

<i class='icon icon-password-hide'></i> <span>icon icon-password-hide</span>

<i class='icon icon-password-show'></i> <span>icon icon-password-show</span>

<i class='icon icon-pause'></i> <span>icon icon-pause</span>

<i class='icon icon-payment'></i> <span>icon icon-payment</span>

<i class='icon icon-pen-to-square'></i> <span>icon icon-pen-to-square</span>

<i class='icon icon-phone'></i> <span>icon icon-phone</span>

<i class='icon icon-pinterest'></i> <span>icon icon-pinterest</span>

<i class='icon icon-play'></i> <span>icon icon-play</span>

<i class='icon icon-plus'></i> <span>icon icon-plus</span>

<i class='icon icon-print'></i> <span>icon icon-print</span>

<i class='icon icon-produktfinder'></i> <span>icon icon-produktfinder</span>

<i class='icon icon-produktvergleich'></i> <span>icon icon-produktvergleich</span>

<i class='icon icon-question'></i> <span>icon icon-question</span>

<i class='icon icon-quickorder'></i> <span>icon icon-quickorder</span>

<i class='icon icon-register'></i> <span>icon icon-register</span>

<i class='icon icon-reload'></i> <span>icon icon-reload</span>

<i class='icon icon-retoure'></i> <span>icon icon-retoure</span>

<i class='icon icon-rma'></i> <span>icon icon-rma</span>

<i class='icon icon-search'></i> <span>icon icon-search</span>

<i class='icon icon-selbstbestimmtheit'></i> <span>icon icon-selbstbestimmtheit</span>

<i class='icon icon-service'></i> <span>icon icon-service</span>

<i class='icon icon-settings'></i> <span>icon icon-settings</span>

<i class='icon icon-share-light'></i> <span>icon icon-share-light</span>

<i class='icon icon-share'></i> <span>icon icon-share</span>

<i class='icon icon-shipping'></i> <span>icon icon-shipping</span>

<i class='icon icon-sonderanfertigung'></i> <span>icon icon-sonderanfertigung</span>

<i class='icon icon-sorting'></i> <span>icon icon-sorting</span>

<i class='icon icon-spielwert'></i> <span>icon icon-spielwert</span>

<i class='icon icon-star-filled'></i> <span>icon icon-star-filled</span>

<i class='icon icon-star-half'></i> <span>icon icon-star-half</span>

<i class='icon icon-star'></i> <span>icon icon-star</span>

<i class='icon icon-subscriptions'></i> <span>icon icon-subscriptions</span>

<i class='icon icon-sympathisches-team'></i> <span>icon icon-sympathisches-team</span>

<i class='icon icon-tiktok'></i> <span>icon icon-tiktok</span>

<i class='icon icon-trash'></i> <span>icon icon-trash</span>

<i class='icon icon-twitter'></i> <span>icon icon-twitter</span>

<i class='icon icon-upload'></i> <span>icon icon-upload</span>

<i class='icon icon-user-plus'></i> <span>icon icon-user-plus</span>

<i class='icon icon-user'></i> <span>icon icon-user</span>

<i class='icon icon-users'></i> <span>icon icon-users</span>

<i class='icon icon-wertschaetzung'></i> <span>icon icon-wertschaetzung</span>

<i class='icon icon-whatsapp'></i> <span>icon icon-whatsapp</span>

<i class='icon icon-xing'></i> <span>icon icon-xing</span>

<i class='icon icon-youtube'></i> <span>icon icon-youtube</span>

</div>

Styleguide Base.Icons
*/

@font-face {
  font-family: "icons";
  font-display: swap;
  src: url('../fonts/icons.woff2') format('woff2'),
  url('../fonts/icons.woff') format('woff');
}

.icon {
  font-family: "icons";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
}


$i-academy: "\E001";

$i-action: "\E002";

$i-address: "\E003";

$i-addressbook: "\E004";

$i-altersvorsorge: "\E005";

$i-angle-down: "\E006";

$i-angle-left: "\E007";

$i-angle-right: "\E008";

$i-angle-up: "\E009";

$i-arrow-down: "\E00A";

$i-arrow-left: "\E00B";

$i-arrow-right: "\E00C";

$i-arrow-up: "\E00D";

$i-basket: "\E00E";

$i-chart: "\E00F";

$i-chat: "\E010";

$i-check-circle: "\E011";

$i-check: "\E012";

$i-circle-arrow-right: "\E013";

$i-circle-close: "\E014";

$i-clock: "\E015";

$i-close: "\E016";

$i-columns: "\E017";

$i-copy: "\E018";

$i-date: "\E019";

$i-document-archive: "\E01A";

$i-download: "\E01B";

$i-drag-drop: "\E01C";

$i-edit: "\E01D";

$i-exclamation-circle: "\E01E";

$i-exclamation: "\E01F";

$i-eye: "\E020";

$i-facebook: "\E021";

$i-file-belege: "\E022";

$i-file-doc: "\E023";

$i-file-document: "\E024";

$i-file-image: "\E025";

$i-file-pdf: "\E026";

$i-file-powerpoint: "\E027";

$i-file-video: "\E028";

$i-file-xls: "\E029";

$i-filter: "\E02A";

$i-fitness: "\E02B";

$i-gift: "\E02C";

$i-global: "\E02D";

$i-haende-herz: "\E02E";

$i-happy-kind: "\E02F";

$i-heart-filled: "\E030";

$i-heart: "\E031";

$i-home: "\E032";

$i-info: "\E033";

$i-instagram: "\E034";

$i-invoice: "\E035";

$i-jobrad: "\E036";

$i-language: "\E037";

$i-linkedin: "\E038";

$i-list: "\E039";

$i-loading: "\E03A";

$i-lock: "\E03B";

$i-logged: "\E03C";

$i-login: "\E03D";

$i-logout: "\E03E";

$i-mail: "\E03F";

$i-map-pin: "\E040";

$i-marketing: "\E041";

$i-massgeschneidert: "\E042";

$i-minus: "\E043";

$i-nachhaltig: "\E044";

$i-password-hide: "\E045";

$i-password-show: "\E046";

$i-pause: "\E047";

$i-payment: "\E048";

$i-pen-to-square: "\E049";

$i-phone: "\E04A";

$i-pinterest: "\E04B";

$i-play: "\E04C";

$i-plus: "\E04D";

$i-print: "\E04E";

$i-produktfinder: "\E04F";

$i-produktvergleich: "\E050";

$i-question: "\E051";

$i-quickorder: "\E052";

$i-register: "\E053";

$i-reload: "\E054";

$i-retoure: "\E055";

$i-rma: "\E056";

$i-search: "\E057";

$i-selbstbestimmtheit: "\E058";

$i-service: "\E059";

$i-settings: "\E05A";

$i-share-light: "\E05B";

$i-share: "\E05C";

$i-shipping: "\E05D";

$i-sonderanfertigung: "\E05E";

$i-sorting: "\E05F";

$i-spielwert: "\E060";

$i-star-filled: "\E061";

$i-star-half: "\E062";

$i-star: "\E063";

$i-subscriptions: "\E064";

$i-sympathisches-team: "\E065";

$i-tiktok: "\E066";

$i-trash: "\E067";

$i-twitter: "\E068";

$i-upload: "\E069";

$i-user-plus: "\E06A";

$i-user: "\E06B";

$i-users: "\E06C";

$i-wertschaetzung: "\E06D";

$i-whatsapp: "\E06E";

$i-xing: "\E06F";

$i-youtube: "\E070";



.icon-academy:before {
  content: "\E001";
}

.icon-action:before {
  content: "\E002";
}

.icon-address:before {
  content: "\E003";
}

.icon-addressbook:before {
  content: "\E004";
}

.icon-altersvorsorge:before {
  content: "\E005";
}

.icon-angle-down:before {
  content: "\E006";
}

.icon-angle-left:before {
  content: "\E007";
}

.icon-angle-right:before {
  content: "\E008";
}

.icon-angle-up:before {
  content: "\E009";
}

.icon-arrow-down:before {
  content: "\E00A";
}

.icon-arrow-left:before {
  content: "\E00B";
}

.icon-arrow-right:before {
  content: "\E00C";
}

.icon-arrow-up:before {
  content: "\E00D";
}

.icon-basket:before {
  content: "\E00E";
}

.icon-chart:before {
  content: "\E00F";
}

.icon-chat:before {
  content: "\E010";
}

.icon-check-circle:before {
  content: "\E011";
}

.icon-check:before {
  content: "\E012";
}

.icon-circle-arrow-right:before {
  content: "\E013";
}

.icon-circle-close:before {
  content: "\E014";
}

.icon-clock:before {
  content: "\E015";
}

.icon-close:before {
  content: "\E016";
}

.icon-columns:before {
  content: "\E017";
}

.icon-copy:before {
  content: "\E018";
}

.icon-date:before {
  content: "\E019";
}

.icon-document-archive:before {
  content: "\E01A";
}

.icon-download:before {
  content: "\E01B";
}

.icon-drag-drop:before {
  content: "\E01C";
}

.icon-edit:before {
  content: "\E01D";
}

.icon-exclamation-circle:before {
  content: "\E01E";
}

.icon-exclamation:before {
  content: "\E01F";
}

.icon-eye:before {
  content: "\E020";
}

.icon-facebook:before {
  content: "\E021";
}

.icon-file-belege:before {
  content: "\E022";
}

.icon-file-doc:before {
  content: "\E023";
}

.icon-file-document:before {
  content: "\E024";
}

.icon-file-image:before {
  content: "\E025";
}

.icon-file-pdf:before {
  content: "\E026";
}

.icon-file-powerpoint:before {
  content: "\E027";
}

.icon-file-video:before {
  content: "\E028";
}

.icon-file-xls:before {
  content: "\E029";
}

.icon-filter:before {
  content: "\E02A";
}

.icon-fitness:before {
  content: "\E02B";
}

.icon-gift:before {
  content: "\E02C";
}

.icon-global:before {
  content: "\E02D";
}

.icon-haende-herz:before {
  content: "\E02E";
}

.icon-happy-kind:before {
  content: "\E02F";
}

.icon-heart-filled:before {
  content: "\E030";
}

.icon-heart:before {
  content: "\E031";
}

.icon-home:before {
  content: "\E032";
}

.icon-info:before {
  content: "\E033";
}

.icon-instagram:before {
  content: "\E034";
}

.icon-invoice:before {
  content: "\E035";
}

.icon-jobrad:before {
  content: "\E036";
}

.icon-language:before {
  content: "\E037";
}

.icon-linkedin:before {
  content: "\E038";
}

.icon-list:before {
  content: "\E039";
}

.icon-loading:before {
  content: "\E03A";
}

.icon-lock:before {
  content: "\E03B";
}

.icon-logged:before {
  content: "\E03C";
}

.icon-login:before {
  content: "\E03D";
}

.icon-logout:before {
  content: "\E03E";
}

.icon-mail:before {
  content: "\E03F";
}

.icon-map-pin:before {
  content: "\E040";
}

.icon-marketing:before {
  content: "\E041";
}

.icon-massgeschneidert:before {
  content: "\E042";
}

.icon-minus:before {
  content: "\E043";
}

.icon-nachhaltig:before {
  content: "\E044";
}

.icon-password-hide:before {
  content: "\E045";
}

.icon-password-show:before {
  content: "\E046";
}

.icon-pause:before {
  content: "\E047";
}

.icon-payment:before {
  content: "\E048";
}

.icon-pen-to-square:before {
  content: "\E049";
}

.icon-phone:before {
  content: "\E04A";
}

.icon-pinterest:before {
  content: "\E04B";
}

.icon-play:before {
  content: "\E04C";
}

.icon-plus:before {
  content: "\E04D";
}

.icon-print:before {
  content: "\E04E";
}

.icon-produktfinder:before {
  content: "\E04F";
}

.icon-produktvergleich:before {
  content: "\E050";
}

.icon-question:before {
  content: "\E051";
}

.icon-quickorder:before {
  content: "\E052";
}

.icon-register:before {
  content: "\E053";
}

.icon-reload:before {
  content: "\E054";
}

.icon-retoure:before {
  content: "\E055";
}

.icon-rma:before {
  content: "\E056";
}

.icon-search:before {
  content: "\E057";
}

.icon-selbstbestimmtheit:before {
  content: "\E058";
}

.icon-service:before {
  content: "\E059";
}

.icon-settings:before {
  content: "\E05A";
}

.icon-share-light:before {
  content: "\E05B";
}

.icon-share:before {
  content: "\E05C";
}

.icon-shipping:before {
  content: "\E05D";
}

.icon-sonderanfertigung:before {
  content: "\E05E";
}

.icon-sorting:before {
  content: "\E05F";
}

.icon-spielwert:before {
  content: "\E060";
}

.icon-star-filled:before {
  content: "\E061";
}

.icon-star-half:before {
  content: "\E062";
}

.icon-star:before {
  content: "\E063";
}

.icon-subscriptions:before {
  content: "\E064";
}

.icon-sympathisches-team:before {
  content: "\E065";
}

.icon-tiktok:before {
  content: "\E066";
}

.icon-trash:before {
  content: "\E067";
}

.icon-twitter:before {
  content: "\E068";
}

.icon-upload:before {
  content: "\E069";
}

.icon-user-plus:before {
  content: "\E06A";
}

.icon-user:before {
  content: "\E06B";
}

.icon-users:before {
  content: "\E06C";
}

.icon-wertschaetzung:before {
  content: "\E06D";
}

.icon-whatsapp:before {
  content: "\E06E";
}

.icon-xing:before {
  content: "\E06F";
}

.icon-youtube:before {
  content: "\E070";
}