/*
Tables

Markup:
<table>
<tr>
<th>Header</th>
<th>Header</th>
<th>Header</th>
<th>Header</th>
<th>Header</th>
</tr>
<tr>
<td>Cell</td>
<td>Cell</td>
<td>Cell</td>
<td>Cell</td>
<td>Cell</td>
</tr>
<tr>
<td>Cell2</td>
<td>Cell2</td>
<td>Cell2</td>
<td>Cell2</td>
<td>Cell2</td>
</tr>
</table>


Styleguide Base.Tables
*/
/*
Tables mit Header

Markup:
<table class="table--headerRost">
<tr>
<th>Header</th>
<th>Header</th>
<th>Header</th>
<th>Header</th>
<th>Header</th>
</tr>
<tr>
<td>Cell</td>
<td>Cell</td>
<td>Cell</td>
<td>Cell</td>
<td>Cell</td>
</tr>
<tr>
<td>Cell2</td>
<td>Cell2</td>
<td>Cell2</td>
<td>Cell2</td>
<td>Cell2</td>
</tr>
</table>


Styleguide Base.Tables mit Header
*/

table {
  font-size: $font-size-base;
  &.table--headerRost {
    th {
      background: $primary-rost;
      color: $white;
      line-height: 1;
    }
  }
}

th {
  font-size: 1.4rem;
  padding: 7px 30px;
  color: $primary-rost;
}

td {
  padding: 14px 30px;
  border-bottom: 0;
}

tr {
  &:nth-child(even) {
    background: $soft-gray;
  }
}


tr:first-child {
  td,th {
    border-top: 1px solid $tableBorderColor;
  }
}
.table_wrapper {
  max-width: 100%;
  overflow-x: scroll;

  @media (max-width: $screen-xs-max){
    table{
      font-size: $font-size-small;
    }
  }
}

.documentLabel {
  color: $main-color-primary-cyan;
  font-weight: $bold;
  position:absolute;
  right: 0;
  top: -5px;
  font-size: 1.5rem;
  @media(max-width: $screen-xlg) {
   font-size: 10px;
  }
}
