/*
Link with Icon

Markup:
<a href="#" class="linkWithIcon {{modifier_class}}"><i class="icon icon-favorites"></i><span>Text</span></a>

:hover - Hover
:focus - Focus
:active - Active
.is-loading - Loading
.is-success - Success
.is-error - Error

Styleguide Typo.Link with Icon
*/

.linkWithIcon {
  display: inline-flex;
  align-items: center;
  color: $text-base;
  text-decoration: none;
  line-height: 1;

  .icon {
    font-size: 1.2em;
    color: $primary;

    &:first-child {
      margin-right: 5px;
    }

    &:last-child {
      margin-left: 5px;
    }
  }

  &:hover, &:focus, &:active {
    text-decoration: none;
    color: $primary;
  }

  &.is-loading,
  &.is-success,
  &.is-error {
    color: $middle-gray !important;
    cursor: not-allowed;
    position: relative;
    pointer-events: none;
    background: transparent !important;

    &:after {
      display: none;
    }

    &:before {
      font-family: $icon-family;
      position: absolute;
      left: 0;
      top: 50%;
      line-height: 20px;
      margin-top: -10px;
    }

    .icon {
      opacity: 0;
    }
  }

  &.is-loading {
    cursor: wait;

    &:before {
      content: $i-loading;
      opacity: 1;
      animation: rotation 1s infinite ease-in-out;
    }
  }

  &.is-success {
    color: $success !important;

    &:before {
      content: $i-check;
      animation: scaleRequestIcon 1s forwards ease-in-out;
    }
  }

  &.is-error {
    color: $danger !important;

    &:before {
      content: $i-close;
      animation: scaleRequestIcon 1s forwards ease-in-out;
    }
  }
}

button.linkWithIcon {
  border: 0;
  padding: 0;
  background: transparent;
  color: $text-base;
  text-decoration: none;
  cursor: pointer;

  &:hover, &:focus, &:active {
    text-decoration: none;
    color: $link-hover;
  }
}

a.linkIcon {
  text-decoration: none;
  font-size: 2.2rem;

  .icon {
    color: $primary;
    transition: scale 200ms ease;
  }
  &:hover {
    .icon {
      scale: 1.1;
    }
  }
}