/*
Paddings

Markup:
<strong>Padding</strong></br>
<div class="padding{{modifier_class}} kssBackgroundGray">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,</div><br/>
<strong>Padding Top</strong></br>
<div class="paddingTop{{modifier_class}} kssBackgroundGray">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,</div><br/>
<strong>Padding Right</strong></br>
<div class="paddingRight{{modifier_class}} kssBackgroundGray">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,</div><br/>
<strong>Padding Bottom</strong></br>
<div class="paddingBottom{{modifier_class}} kssBackgroundGray">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,</div><br/>
<strong>Padding Left</strong></br>
<div class="paddingLeft{{modifier_class}} kssBackgroundGray">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,</div>

--xsmall - xSmall Padding
--small - Small Padding
--large - Large Padding
--xlarge - xLarge Padding


Styleguide Structure.Paddings
*/

.padding {
  padding: $spacer-base;

  &Top {
    padding-top: $spacer-base;
  }

  &Right {
    padding-right: $spacer-base;
  }

  &Bottom {
    padding-bottom: $spacer-base;
  }

  &Left {
    padding-left: $spacer-base;
  }
}

$paddings: (
        --small: $spacer-small,
        --large: $spacer-large,
        --xlarge: $spacer-xlarge,
        --xsmall: $spacer-xsmall
);

@each $index, $value in $paddings {

  .padding#{$index} {
    padding: $value;
  }
  .paddingTop#{$index} {
    padding-top: $value;
  }
  .paddingRight#{$index} {
    padding-right: $value;
  }
  .paddingBottom#{$index} {
    padding-bottom: $value;
  }
  .paddingLeft#{$index} {
    padding-left: $value;
  }

}

@each $breakpoint, $size in $spacer-large-breakpoints {
  @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
    .padding--large {
      padding: $size;
    }
    .paddingTop--large {
      padding-top: $size;
    }
    .paddingRight--large {
      padding-right: $size;
    }
    .paddingBottom--large {
      padding-bottom: $size;
    }
    .paddingLeft--large {
      padding-left: $size;
    }
  }
}

@each $breakpoint, $size in $spacer-xlarge-breakpoints {
  @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
    .padding--xlarge {
      padding: $size;
    }
    .paddingTop--xlarge {
      padding-top: $size;
    }
    .paddingRight--xlarge {
      padding-right: $size;
    }
    .paddingBottom--xlarge {
      padding-bottom: $size;
    }
    .paddingLeft--xlarge {
      padding-left: $size;
    }
  }
}

@each $breakpoint, $size in $grid-breakpoints {
  @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
    .#{$breakpoint}-padding {
      padding-bottom: $grid-gap;
    }
    .#{$breakpoint}-padding-base {
      padding-bottom: $spacer-base;
    }
  }
}