$colors:
          $main-color-primary-rost,
          $main-color-primary-beige,
          $main-color-primary-green,
          $main-color-primary-cyan,
          $main-color-primary-blue;
$repeat: 6;

.megaMenu {
  display: none;
  position: absolute;
  left: 0;
  z-index: $zindexHeaderHovermenu;
  background: $main-color-soft-gray;
  width: 100%;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.1);
  padding-top: 30px;
  padding-bottom: 50px;

  li {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  a {
    font-weight: $megaMenuFontWeightBase;
    display: block;
  }

  .container {
    font-size: $megaMenuFontSizeBase;
    display: flex;
    padding-top: 20px;

    .megaMenu__navigation {
      flex-grow: 1
    }

    .megaMenu__content {
      flex-shrink: 0;
      padding-left: $grid-gap;
      width: 25%;
      display: none;
    }

    @media (min-width: $screen-lg) {
      .megaMenu__content {
        display: block;
      }
    }
  }
}

.mainnav--content {
  .megaMenu {
    ul {
      &.level_1 {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        flex-grow: 1;
        gap: $spacer-base;



        li {
          list-style: none;
          padding: 1em;
        }
        > li {
          aspect-ratio: 5/4;
          display: flex;
          padding: 0;
          @for $i from 1 through $repeat {
            &:nth-child(#{length($colors)}n+#{$i}) {
              $selectedColor: nth($colors, random(length($colors)));
              > a {
                background: $selectedColor;
                &:hover {
                  background: darken($selectedColor, 10%);
                }
              }
              &.is-active {
                > a {
                  border: 3px solid darken($selectedColor, 30%);
                }
              }
            }
          }
          > a {
            padding: $spacer-base;
            color: $white;
            font-weight: $bold;
            font-size: 2.1rem;
            margin-bottom: 0;
            flex-grow: 1;
            height: 100%;
            display: flex;
            align-items: flex-end;
          }
        }
      }
    }
  }
}

.mainnav--shop {
  .megaMenu__navigation {
    > ul {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
      flex-grow: 1;
      gap: calc($spacer-base / 2);
      li {
        list-style: none;
        padding: 1em;
      }
      > li {
        aspect-ratio: 5/4;
        display: flex;
        padding: 0;
        &:first-child {
          grid-row: span 2;
          aspect-ratio: auto;
          > a {
            font-size: 3.2rem;
            background: $primary-beige;
            &:after {
              content: none;
            }
            &:hover {
              background: darken($main-color-primary-beige, 10);
            }
            @media (min-width: $screen-xlg) {
              font-size: 4.2rem;
              padding-left: $spacer-base*2;
            }
          }

        }
        &:not(.showAll) {

        }
        > a {
          padding: $spacer-base;
          color: $white;
          font-weight: $bold;
          font-size: 2.1rem;
          margin-bottom: 0;
          flex-grow: 1;
          height: 100%;
          display: flex;
          align-items: flex-end;
          background: $primary-rost;
          position: relative;
          overflow: hidden;
          &:after {
            display: none;
            content:"";
            position: absolute;
            width: 500px;
            height: 500px;
            bottom: -130px;
            left: -130px;
            background: $primary-rost;
            z-index: 2;
            border-radius: 50%;
            animation: hoverMenu .6s ease-in;
          }
          > .megaMenu__categoryText {
            z-index: 3
          }
          &:hover {
            &:after {
              display: block;
            }
          }
          @keyframes hoverMenu {
            0% {
              bottom: -400px;
              left: -400px;
            }
            25% {
              bottom: -300px;
              left: -300px;
            }
            50% {
              bottom: -200px;
              left: -200px;
            }
            75% {
              bottom: -130px;
              left: -130px;
            }
            100% {
              bottom: -130px;
              left: -130px;
            }
          }
        }
        &.is-active-tree {
          > a {
            .megaMenu__categoryImg {
              display: none;
            }
          }
        }

      }
    }
  }
  .megaMenu__categoryImg {
    z-index: 1;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      background: rgba(0,0,0,.3);
      width: 100%;
      height: 100%;
    }
    img {
      position: absolute;
      left: 0;
      top: 0;
      aspect-ratio: 5/4;
      object-fit: cover;
      z-index: -1;
      width: 100%;
      height: 100%;
    }

  }
}

