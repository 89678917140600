/*
File Input

Markup:
<div class="fileBody ">
    <input class="{{modifier_class}}" name="input_file" id="input_file" type="file" value="" multiple>
    <span>Dateien auswählen</span>
</div>

:focus    - Focus
:active   - Active
:disabled - Disabled
.error - Error

Styleguide Form.File Input
*/

.fileBody {
  display: block;
  position: relative;
  width: 100%;

  input {
    display: block;
    position: absolute;
    left:0;
    top:0;
    width: 100%;
    height: 100%;
    opacity: 0;
    margin:0;
    padding:0;
    cursor: pointer;

    + span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      background-color: $inputBackground;
      color: $inputColor;
      border: $inputBorderWidth solid $inputBorderColor;
      border-radius: $inputBorderRadius;
      box-shadow: none;
      width: 100%;
      line-height: $inputLineHeightXS;
      font-size: clamp(16px, $inputFontSizeXS, 20px);
      padding: $inputPaddingTopXS $inputPaddingRightXS $inputPaddingBottomXS $inputPaddingLeftXS;
      min-height: $inputLineHeightXS + $inputPaddingTopXS + $inputPaddingBottomXS + $inputBorderWidth * 2;
      cursor: pointer;
      display: block;
      padding-right: 50px;

      @media (min-width: $screen-sm) {
        line-height: $inputLineHeight;
        font-size: $inputFontSize;
        padding: $inputPaddingTop $inputPaddingRight $inputPaddingBottom $inputPaddingLeft;
        min-height: $inputMinHeight;
      }
    }

    &:not(:disabled) {
      &:active + span, &:focus + span {
        outline: 0;
        border-color: $inputBorderColorFocus;
      }
    }

    &.error + span {
      border-color: $danger;
    }

    &:disabled + span {
      background-color: $light-gray;
      border-color: $light-gray;
    }
  }

  .icon {
    font-size: 2rem;
    width: $inputLineHeightXS + $inputPaddingTopXS + $inputPaddingBottomXS + $inputBorderWidth * 2;
    min-height: $inputLineHeightXS + $inputPaddingTopXS + $inputPaddingBottomXS + $inputBorderWidth * 2;
    position: absolute;
    right:0;
    top:0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    @media (min-width: $screen-sm) {
      min-height: $inputMinHeight;
      width: $inputMinHeight;
    }
  }
}