/*
Pagination

Markup:
<div class="pagination">
    <a class="pagination__item pagination__item--prev" href="?page=2&amp;sort_by=ranking">
        <i class="icon icon-angle-left"></i>
    </a>
    <a class="pagination__item " href="?page=1&amp;sort_by=ranking">1</a>
    <a class="pagination__item " href="?page=2&amp;sort_by=ranking">2</a>
    <a class="pagination__item pagination__item--active" href="?page=3&amp;sort_by=ranking">3</a>
    <span class="pagination__item pagination__item--seperator">...</span>
    <a class="pagination__item " href="?page=5&amp;sort_by=ranking">5</a>
    <a class="pagination__item pagination__item--prev" href="?page=4&amp;sort_by=ranking">
        <i class="icon icon-angle-right"></i>
    </a>
</div>

Styleguide Components.Pagination
*/

.pagination {
  display: flex;
  align-items: center;
  justify-content: end;

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 20px;
    text-align: center;
    line-height: 1;
    text-decoration: none;
    margin-left: 5px;
    margin-right: 5px;
    color: $paginationColor;
    background: $paginationBackground;
    padding:0;
    border:0;
    border-radius: 0;
    margin-bottom: 0;

    &--active {
      background: $paginationBackgroundActive;
      color: $paginationColorActive !important;
    }

    > a {
      text-decoration: none;
      color: unset;
    }
    &:hover, &:focus, &:active {
      background: $paginationLinkBackgroundHover;
      color: $paginationLinkColorHover;
      text-decoration: none;
    }
  }

  .icon {
    font-size: 18px;
    color: $black;
  }
}

button.pagination__item,
a.pagination__item {
  color: $paginationLinkColor;
  cursor: pointer;

  &:hover, &:focus, &:active {
    background: $paginationLinkBackgroundHover;
    color: $paginationLinkColorHover;
    text-decoration: none;
  }
}