ul {
  &.checkList {
    list-style: none;
    padding-left: 25px;

    li {
      position: relative;

      &:before {
        content: $i-check;
        font-family: $icon-family;
        position: absolute;
        left: -25px;
        font-size: 20px;
        margin-top: -1px;
      }
    }
  }
}