.container--middle {
  max-width: $container-middle;
}

.container-fluid {
  max-width: 1920px;
}

@media(max-width: $screen-xs) {
  .container {
    padding-inline: $spacer-base;
  }
}