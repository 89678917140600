/*
Margins

Markup:
<strong>Margin</strong></br>
<div class="margin{{modifier_class}}"><div class="kssPaddingBox">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,</div></div>
<strong>Margin Top</strong></br>
<div class="marginTop{{modifier_class}}"><div class="kssPaddingBox">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,</div></div>
<strong>Margin Right</strong></br>
<div class="marginRight{{modifier_class}}"><div class="kssPaddingBox">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,</div></div>
<strong>Margin Bottom</strong></br>
<div class="marginBottom{{modifier_class}}"><div class="kssPaddingBox">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,</div></div>
<strong>Margin Left</strong></br>
<div class="marginLeft{{modifier_class}}"><div class="kssPaddingBox">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,</div></div>

--xsmall - xSmall Margin
--small - Small Margin
--large - Large Margin
--xlarge - xLarge Margin


Styleguide Structure.Margins
*/

.margin {
  margin: $spacer-base;

  &Top {
    margin-top: $spacer-base;
  }

  &Right {
    margin-right: $spacer-base;
  }

  &Bottom {
    margin-bottom: $spacer-base;
  }

  &Left {
    margin-left: $spacer-base;
  }
}

$margins: (
        --small: $spacer-small,
        --large: $spacer-large,
        --xlarge: $spacer-xlarge,
        --xsmall: $spacer-xsmall
);

@each $index, $value in $margins {

  .margin#{$index} {
    margin: $value;
  }
  .marginTop#{$index} {
    margin-top: $value;
  }
  .marginRight#{$index} {
    margin-right: $value;
  }
  .marginBottom#{$index} {
    margin-bottom: $value;
  }
  .marginLeft#{$index} {
    margin-left: $value;
  }

}

@each $breakpoint, $size in $spacer-large-breakpoints {
  @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
    .margin--large {
      margin: $size;
    }
    .marginTop--large {
      margin-top: $size;
    }
    .marginRight--large {
      margin-right: $size;
    }
    .marginBottom--large {
      margin-bottom: $size;
    }
    .marginLeft--large {
      margin-left: $size;
    }
  }
}

@each $breakpoint, $size in $spacer-xlarge-breakpoints {
  @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
    .margin--xlarge {
      margin: $size;
    }
    .marginTop--xlarge {
      margin-top: $size;
    }
    .marginRight--xlarge {
      margin-right: $size;
    }
    .marginBottom--xlarge {
      margin-bottom: $size;
    }
    .marginLeft--xlarge {
      margin-left: $size;
    }
  }
}

@each $breakpoint, $size in $grid-breakpoints {
  @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
    .#{$breakpoint}-margin {
      margin-bottom: $grid-gap;
    }
    .#{$breakpoint}-margin-base {
      margin-bottom: $spacer-base;
    }
  }
}

.marginBottom--0 {
  margin-bottom: 0;
}