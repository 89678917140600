.iconbarIcon {

  &__icon {
    font-size: $inputGroupAddonIconFontSizeXS;

    @media (min-width: $switchMobileMenu) {
      font-size: $inputGroupAddonIconFontSize;
    }
  }

  &__badge {
    width: $iconbarBadgeWidth;
    height: $iconbarBadgeHeight;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-shrink: 0;
    margin-left: 10px;
    background: $iconbarBadgeBackgroundColor;
    color: $iconbarBadgeColor;
  }

  &__label {
    font-size: $iconbarIconLabelFontSize;
    line-height: 1;
    display: none;

    @media (min-width: $showIconLabelBreakpoint) {
      display: block;
    }
  }

  &__count {
    position: absolute;
    right: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    font-size: .8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    background: $iconbarIconCountBackground;
    color: $iconbarIconCountColor;

    @media (min-width: $showIconLabelBreakpoint) {
      right: calc($iconbarSpaceBetweenIcons / 2);
    }

    &.is-action {
      display: flex !important;
      animation: scaleRequestIconBigger 0.5s forwards ease-in-out;
    }
  }

  &:hover, &:active, &.is-active {
    .iconbarIcon__count {
      color: $primary-red-contrast;
    }
  }
}