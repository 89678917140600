.paddingLeft--inContainer {
  padding-left: 20px;
  @media(min-width: $container) {
    padding-left: calc((100% - ($container - 40px))/2);
  }
}

.paddingTop--0 {
  padding-top: 0!important;
}

.paddingBottom--0 {
  padding-bottom: 0!important;
}