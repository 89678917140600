/*
Button

Markup:
<a href="#" class="button {{modifier_class}}">Button</a> <button class="button {{modifier_class}}">Button</button>

.button--primary - Primary
.button--secondary - Secondary
.button--action - Action
.button--danger - Danger
.button--link - Link
.button--linkWithIcon - Link mit Icon
.button--disabled - Disabled

Styleguide Base.Buttons
*/
/*
Button Special

Markup:
<a href="#" class="{{modifier_class}}"></a>

.button--icon - Icon
.button--icon.button--iconRed - Icon Red

Styleguide Base.Buttons Special
*/

%buttonHover {
  background: $buttonBackgroundHover;
  border-color: $buttonBorderColorHover;
  color: $buttonColorHover;
  text-decoration: none;
}

%buttonLinkHover {
  background: transparent;
  text-decoration: none;
  &:after {
    width: 100%;
  }
}

%button,
button,
.button {
  display: inline-block;
  vertical-align: middle;
  border: 1px solid $buttonBorderColor;
  border-radius: $buttonBorderRadius;
  color: $buttonColor;
  cursor: pointer;
  background: $buttonBackground;
  text-align: center;
  text-decoration: none;
  line-height: $inputLineHeightXS;
  font-size: $inputFontSizeXS;
  padding: $inputPaddingTopXS $inputPaddingRightXS $inputPaddingBottomXS $inputPaddingLeftXS;
  transition: ease-in-out 0.1s;
  position: relative;

  @media (min-width: $screen-sm) {
    line-height: $inputLineHeight;
    font-size: $inputFontSize;
    padding: $inputPaddingTop $inputPaddingRight $inputPaddingBottom $inputPaddingLeft;
  }

  &:hover, &:focus {
    @extend %buttonHover;
  }

  &:active {
    background: $buttonBackgroundActive;
    border-color: $buttonBorderColorActive;
    color: $buttonColorActive;
    text-decoration: none;
  }

  &--primary {
    background: $buttonPrimaryBackground;
    color: $buttonPrimaryColor;
    border-color: $buttonPrimaryBorderColor;

    &:hover, &:focus {
      background: $buttonPrimaryBackgroundHover;
      border-color: $buttonPrimaryBorderColorHover;
      color: $buttonPrimaryColorHover;
    }

    &:active {
      background: $buttonPrimaryBackgroundActive;
      border-color: $buttonPrimaryBorderColorActive;
      color: $buttonPrimaryColorActive;
    }
  }

  &--secondary {
    background: $buttonSecondaryBackground;
    color: $buttonSecondaryColor;
    border-color: $buttonSecondaryBorderColor;

    &:hover, &:focus {
      background: $buttonSecondaryBackgroundHover!important;
      border-color: $buttonSecondaryBorderColorHover!important;
      color: $buttonSecondaryColorHover!important;
    }

    &:active {
      background: $buttonSecondaryBackgroundActive;
      border-color: $buttonSecondaryBorderColorActive;
      color: $buttonSecondaryColorActive;
    }
  }

  &--full {
    width: 100%;
  }

  &--action {
    background: $buttonActionBackground;
    color: $buttonActionColor;
    border-color: $buttonActionBorderColor;

    &:hover, &:focus {
      background: $buttonActionBackgroundHover;
      border-color: $buttonActionBorderColorHover;
      color: $buttonActionColorHover;
    }

    &:active {
      background: $buttonActionBackgroundActive;
      border-color: $buttonActionBorderColorActive;
      color: $buttonActionColorActive;
    }
  }

  &--danger {
    background: $danger;
    color: $white;
    border-color: $danger;

    &:hover, &:focus {
      background: $buttonActionBackgroundHover;
      border-color: $buttonActionBorderColorHover;
      color: $buttonActionColorHover;
    }

    &:active {
      background: $buttonActionBackgroundActive;
      border-color: $buttonActionBorderColorActive;
      color: $buttonActionColorActive;
    }
  }

  &--sm {
    @if ($inputPaddingTopXS != $inputPaddingTop) {
      padding-top: $inputPaddingTopXS;
    }
    @if ($inputPaddingRightXS != $inputPaddingRight) {
      padding-right: $inputPaddingRightXS;
    }
    @if ($inputPaddingBottomXS != $inputPaddingBottom) {
      padding-bottom: $inputPaddingBottomXS;
    }
    @if ($inputPaddingLeftXS != $inputPaddingLeft) {
      padding-left: $inputPaddingLeftXS;
    }
    @if ($inputLineHeightXS != $inputLineHeight) {
      line-height: $inputLineHeightXS;
    }
    @if ($inputFontSizeXS != $inputFontSize) {
      font-size: $inputFontSizeXS;
    }
  }

  @media (min-width: $screen-sm) {
    &--xl {
      @if ($inputPaddingTopXL != $inputPaddingTop) {
        padding-top: $inputPaddingTopXL;
      }
      @if ($inputPaddingRightXL != $inputPaddingRight) {
        padding-right: $inputPaddingRightXL;
      }
      @if ($inputPaddingBottomXL != $inputPaddingBottom) {
        padding-bottom: $inputPaddingBottomXL;
      }
      @if ($inputPaddingLeftXL != $inputPaddingLeft) {
        padding-left: $inputPaddingLeftXL;
      }
      @if ($inputLineHeightXL != $inputLineHeight) {
        line-height: $inputLineHeightXL;
      }
      @if ($inputFontSizeXL != $inputFontSize) {
        font-size: $inputFontSizeXL;
      }
    }
  }

  &.is-loading,
  &.is-success,
  &.is-error {
    background: $middle-gray !important;
    border-color: $middle-gray !important;
    cursor: not-allowed;
    pointer-events: none;
    padding-right: 40px;
    padding-left: 10px;

    .icon {
      opacity: 0;
    }

    &:after {
      position: absolute;
      right: 15px;
      top: 50%;
      font-family: $icon-family;
      translate: 0 -50%;
      font-size: 24px;
    }
  }

  &.is-loading {
    cursor: wait;
    color: $black !important;

    &:after {
      content: $i-loading;
      opacity: 1;
      animation: rotation 1s infinite ease-in-out;
    }
  }

  &.is-success {
    background: $success !important;
    border-color: $success !important;
    color: $black !important;

    &:after {
      content: $i-check;
      animation: scaleRequestIconBtn 1s forwards ease-in-out;
    }
  }

  &.is-error {
    background: $danger !important;
    border-color: $danger !important;
    color: $white !important;

    &:after {
      content: $i-close;
      animation: scaleRequestIconBtn 1s forwards ease-in-out;
    }
  }

  &--icon {
     &-left {
       padding-left: 55px;
       .icon {
         position: absolute;
         left: 25px;
         font-size: 22px;
       }
     }
   }
}

button.disabled,
.button--disabled,
.btn-disabled,
.button[disabled] {
  @extend %button;
  background: $light-gray;
  color: $gray-base;
  border-color: $light-gray;
  cursor: not-allowed;
  pointer-events: none;

  &:hover, &:focus, &:active {
    background: $light-gray;
    border-color: $light-gray;
    color: $gray-base;
  }
}

%button--link,
.button--link {
  display: inline-block;
  color: $text-base;
  cursor: pointer;
  position: relative;
  background-color: transparent;
  padding: 5px 0 3px 0;
  border: 0;
  text-decoration: none;

  &:after {
    content: "";
    display: block;
    width: 0;
    position: absolute;
    left: 0;
    bottom: 0;
    background: $primary-red;
    height: 2px;
    transition: width 0.2s ease-in;
  }

  &:hover, &:focus, &:active {
    @extend %buttonLinkHover;
    color: $text-base;
  }
}

.button--linkWithIcon {
  display: inline-block;
  color: $text-base;
  cursor: pointer;
  position: relative;
  background-color: transparent;
  border: 0;
  text-decoration: none;
  padding: 5px 0 3px 0;
  padding-right: 80px;
  &:after {
    content: $i-arrow-right;
    font-family: $icon-family;
    color: $primary-red;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    position: absolute;
    right: 0;
    top: 50%;
    translate: -50% -50%;
    border: 1px solid $primary-red;
    border-radius: 50%;
  }
  &:before {
    content:"";
    position: absolute;
    width: 34px;
    height: 34px;
    right: 0;
    top: 50%;
    translate: -50% -50%;
    border: 1px solid transparent;
    border-radius: 50%;
    transition: 200ms ease-in;
  }
  &:hover, &:focus, &:active {
    color: $dark-gray;
    background: none!important;
    &:after {
      background: $primary-red;
      color: $white;
    }
    &:before {
      scale: $buttonHoverScale;
      border: 1px solid $primary-red;
    }
  }
}

.button--linkWithIconBorder {
  @extend %button;
  border: 1px solid $black;
  background:transparent;
  &:hover, &:focus, &:active {
    background: $light-gray;
    border-color: $light-gray;
  }
}

.button--icon {
  background:transparent;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  position: relative;
  display: block;
  overflow: visible!important;

  &:after {
    content: $i-arrow-right;
    font-family: $icon-family;
    position: absolute;
    left: 50%;
    top:50%;
    translate: -50% -50%;
    color: $primary;
  }
  &:before {
    content:"";
    position: absolute;
    width: 34px;
    height: 34px;
    left: 50%;
    top:50%;
    translate: -50% -50%;
    border: 1px solid $primary-red;
    border-radius: 50%;
    transition: 200ms ease-in;
  }
  &:hover, &:focus,&:active {
    background: $primary;
    &:after {
      color: $white;
    }
    &:before {
      scale: $buttonHoverScale;
      border: 1px solid $primary-red;
    }
  }
  &Red {
    background: $primary;
    &:after {
      color: $white;
    }
  }
}

button.button--icon {
  padding: 0;
}

.button--transparent {
  background: transparent;
  border-color: $white;
  color: $white!important;
  &:hover {
    background: transparent!important;
    border-color: $white!important;
  }
  &.linkWithIcon {
    &:hover {
      color: $white!important;
      i {
        color: $primary;
      }
    }
  }
}

.button--underline {
  @extend %button--link;
  &:after {
    width: 100%;
  }

  &:hover, &:focus, &:active {
    color: $primary;
  }

}

@keyframes scaleRequestIconBtn {
  0% {
    opacity: 0;
    scale: 0
  }
  50% {
    opacity: 1;
    scale: 1.2
  }
  100% {
    opacity: 1;
    scale: 1
  }
}