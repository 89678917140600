
.lottieContainer {
  max-width: 100%;
  width: 100%;
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;
}