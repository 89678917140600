.inputGroup__addon {
  .icon {
    font-size: $inputGroupAddonIconFontSizeXS;
    @media (min-width: $screen-md) {
      font-size: $inputGroupAddonIconFontSize;
    }
  }
}

input {
  &::placeholder {
    color: $black!important;
  }
}