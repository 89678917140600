$header-top-container: 1920px;
$header-background: $white;

$header-main-container: 1920px;
$headerComponentsSpaceBetweenXS: 10px;
$headerComponentsSpaceBetween: 40px;

.headerLogo {
  max-width: 182px;
  @media(min-width: $screen-md-min) {
    max-width: 190px;

  }
  @media(min-width: $screen-lg-min) {
    max-width: 280px;

  }
  @media (min-width: $screen-lg) {
    margin-right: 0;
  }
}

.logoAnimated {
  > a {
    display: grid;
    grid-template-columns: 1.4fr 1fr;
    align-items: center;
    gap: $spacer-base;

    > img {
      object-fit: cover;
    }
  }
  @media (min-width: $screen-lg) {
    margin-right: 0;
    >a {
      height: 88px !important;
      gap: 27px;
      > img {
        height: auto !important;
      }
      > span {
        width: 90%;
      }
    }
  }
}

.headerTop {
  background: $main-color-primary-rost;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 1.3rem;
  line-height: 20px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;


  > div {
    color: $white;
    font-size: 13px;
    font-weight: $bold;

    @media (min-width: $screen-md) {
      font-size: 17px;
    }
  }



  @media (min-width: $screen-sm) {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .container {
    max-width: $header-top-container;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .customSelect {
    &__selected {
      font-size: 1.3rem;
      line-height: 1;
    }
  }
}

.headerMain {
  justify-content: center;

  &Left {

    .nav {
      ul {
        gap: 30px !important;
      }
    }
    @media (min-width: $screen-md) {
      gap: 25px !important;
    }
    @media (min-width: $screen-lg) {
      gap: 40px !important;
    }
  }
}

.headerNavigation {
  @media (min-width: 801px) {
    display: none !important;
  }
  @media (min-width: $screen-md) {
    display: flex !important;
  }
}

.headerLogout {
  > a {
    > span {
      display: none;

      @media (min-width: $screen-md) {
        display: block;
      }
    }
  }
}