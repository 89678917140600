%gradientOverlay {
  position: relative;
  overflow: hidden;
    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      background: linear-gradient(0, rgba(0, 0, 0, 0.35) 0%, rgba(255, 255, 255, 0) 100%);
  }
}

%gradientOverlayFull {
  position: relative;
  overflow: hidden;

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    left: 0;
    bottom: 0;
  }
}

.gradientOverlay {
  .banner {
    .contentImage {
      @extend %gradientOverlay;
    }
  }
}

.gradientOverlayFull {
  .banner {
    .contentImage {
      @extend %gradientOverlayFull;
    }
  }
}