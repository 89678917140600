@each $breakpoint, $size in $spacer-small-breakpoints {
  @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
    .margin--small {
      margin: $size;
    }
    .marginTop--small {
      margin-top: $size;
    }
    .marginRight--small {
      margin-right: $size;
    }
    .marginBottom--small {
      margin-bottom: $size;
    }
    .marginLeft--small {
      margin-left: $size;
    }
  }
}