/*
Inputs

Markup:
<strong>Text</strong><br/>
<input type="text" class='{{modifier_class}}' value="Text" /><br/>
<strong>E-Mail</strong><br/>
<input type="email" class='{{modifier_class}}' value="info@dc.ag" /><br/>
<strong>Number</strong><br/>
<input type="number" class='{{modifier_class}}' value="123" /><br/>
<strong>Date</strong><br/>
<input type="date" class='{{modifier_class}}' value="2025-01-01" /><br/>
<strong>Textarea</strong><br/>
<textarea class='{{modifier_class}}'>Text</textarea><br/>
<strong>Select</strong><br/>
<div class="selectBody">
<select class='{{modifier_class}}'>
  <option disabled selected>Choose</option>
  <option>Option 1</option>
  <option>Option 2</option>
  <option>Option 3</option>
</select>
</div>

:focus    - Focus
:active   - Active
:disabled - Disabled
:valid - Valid
.error - Error

Styleguide Form.Inputs
*/

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $white inset !important;
}

input,
select,
textarea,
%inputHelper {
  background-color: $inputBackground;
  color: $inputColor;
  border: $inputBorderWidth solid $inputBorderColor;
  border-radius: $inputBorderRadius;
  box-shadow: none;
  display: block;
  width: 100%;
  line-height: $inputLineHeightXS;
  font-size: clamp(16px, $inputFontSizeXS, 20px);
  padding: $inputPaddingTopXS $inputPaddingRightXS $inputPaddingBottomXS $inputPaddingLeftXS;

  @media (min-width: $screen-sm) {
    line-height: $inputLineHeight;
    font-size: $inputFontSize;
    padding: $inputPaddingTop $inputPaddingRight $inputPaddingBottom $inputPaddingLeft;
  }

  &:not(:disabled) {
    &:active, &:focus {
      outline: 0;
      border-color: $inputBorderColorFocus;
    }
  }

  &.error {
    border-color: $danger;
  }

  &:disabled {
    background-color: $white;
    border-color: $light-gray;
    color: $light-gray;
  }
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=submit] {
  width: auto;
}

input[type=number] {
  -moz-appearance: textfield;
}

input[type="file"] {
  & + .full {
    flex-grow: 1;
    padding-left: 15px;
    padding-right: 15px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;

    i {
      font-size: 18px;
    }
  }

  &:hover {
    & + .full {
      i {
        color: $primary;
      }
    }
  }
}

textarea {
  resize: vertical;
}

%selectBodyAfter {
  content: $i-angle-down;
  font-family: $icon-family;
  position: absolute;
  right: $spacer-base*1.5;
  top: 50%;
  font-size: 18px;
  pointer-events: none;
  transform: translateY(-50%);
  margin-top: -1px;
}

.selectBody {
  background-color: $inputBackground;
  cursor: pointer;
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:after {
    @extend %selectBodyAfter;
  }

  select {
    color: $inputColor;
    border-color: $inputBorderColor;
    cursor: pointer;
    padding-right: 40px;
    appearance: none;
    display: block;
    width: 100%;

    &::-ms-expand {
      display: none;
    }
  }

  &.focus {
    select {
      border-color: $inputBorderColorFocus;
    }
  }

  &.disabled {
    select {
      cursor: default;
    }
  }
}

form {
  margin:0;
}

.form-group--inputs {
  display: flex;
  align-items: center;
  gap: $spacer-base;
  .selectBody {
    max-width: 300px;
  }
  input {
    max-width: 350px;
  }

  @media(min-width: $screen-lg) {
    gap: 50px;

  }
}

.form--wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: $spacer-base;
  > * {
    &:first-child {
      flex-grow: 1;
    }
  }
}