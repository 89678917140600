.tileBox {
  display: flex;
  position: relative;
  min-height: 150px;
  .textcontent {
    flex-grow: 1;
    padding: $spacer-base;
    gap: $spacer-base*2;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-size: $font-size-small;
    overflow: hidden;
  }
  .tile--date {
    margin-bottom: $spacer-base;
  }

  .tile--main {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  a, .link {
    display: flex;
    flex-grow: 1;
    text-decoration: none;
    align-items: flex-end;
    > div {
      flex-grow: 1;
      height: 100%;
      display: flex;
    }

  }

  @media(min-width: $screen-sm) {
    .textcontent {
      padding: $spacer-base*1.5;
      gap: $spacer-base*1.5;
      font-size: $font-size-base;
    }
  }

  @media(min-width: $screen-xlg) {
    .textcontent {
      gap: $spacer-base*3;
    }
  }

  &--linkButton {
    .button--icon, .icon {
      position: absolute;
      top: $spacer-base;
      right: $spacer-base;
    }
    .icon {
      font-size: 3rem;
      line-height: 1;
    }
    a {
      display: flex;
      flex-grow: 1;
      text-decoration: none;
      align-items: flex-end;
      &:hover {
        .button--icon {
          background: $primary;
          &:after {
            color: $white;
          }
          &:before {
            scale: 1.3;
          }
        }
        .icon {
          color: $primary;
        }
      }

    }
  }

  &--large {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: $spacer-base;
    h1,.h1,h2,.h2,h3,.h3,h4,.h4 {
      margin-bottom: 0;
    }
    @media(min-width: $screen-sm) {
      padding: $spacer-base*3 $spacer-base*2;
    }
  }

  &:has(a, .link) {
    z-index: 2;
    height: 100%;
    .textcontent {
      padding: 0;
    }
    a, .link {
      padding: $spacer-base;
      @media(min-width: $screen-sm) {
        padding: $spacer-base*1.5;
      }
    }
  }
}

.tileBorder {
  > .textcontent {
    border: 1px solid $black;
  }
}