@use "sass:math";
@use "sass:map";
@import "../../../../node_modules/bootstrap/scss/functions";

//colors

$main-color-white: #ffffff;
$main-color-black: #333333;
$main-color-gray-base: #919191;
$main-color-middle-gray: #808080;
$main-color-dark-gray: #666666;
$main-color-light-gray: #CCCCCC;
$main-color-soft-gray: #F5F5F5;
$main-color-primary-red: #E30017;
$main-color-primary-red-dark: darken($main-color-primary-red,5);
$main-color-primary-red-darken: darken($main-color-primary-red,10);
$main-color-primary-rost: #CD6049;
$main-color-primary-beige: #BD9C73;
$main-color-primary-green: #9CA87F;
$main-color-primary-cyan: #009EE3;
$main-color-primary-blue: #819AB9;
$main-color-secondary-rost-pastel: #EDA998;
$main-color-secondary-beige-pastel: #D1C0A9;
$main-color-secondary-green-pastel: #BEC6A2;
$main-color-secondary-blue-pastel: #A5C3D9;
$main-color-danger: $main-color-primary-red;
$main-color-warning: #e8e100;
$main-color-success: #8ce300;
$main-color-action: $main-color-black;
$main-color-info: $main-color-light-gray;

$cssColorVariables: (
        "main-color-white": $main-color-white,
        "main-color-black": $main-color-black,
        "main-color-gray-base": $main-color-gray-base,
        "main-color-middle-gray": $main-color-middle-gray,
        "main-color-dark-gray": $main-color-dark-gray,
        "main-color-light-gray": $main-color-light-gray,
        "main-color-soft-gray": $main-color-soft-gray,
        "main-color-primary-red": $main-color-primary-red,
        "main-color-primary-rost": $main-color-primary-rost,
        "main-color-primary-beige": $main-color-primary-beige,
        "main-color-primary-green": $main-color-primary-green,
        "main-color-primary-cyan": $main-color-primary-cyan,
        "main-color-primary-blue": $main-color-primary-blue,
        "main-color-secondary-rost-pastel": $main-color-secondary-rost-pastel,
        "main-color-secondary-beige-pastel": $main-color-secondary-beige-pastel,
        "main-color-secondary-green-pastel": $main-color-secondary-green-pastel,
        "main-color-secondary-blue-pastel": $main-color-secondary-blue-pastel,
        "main-color-action": $main-color-action,
        "main-color-danger": $main-color-danger,
        "main-color-warning": $main-color-warning,
        "main-color-success": $main-color-success,
        "main-color-info": $main-color-info
);

$theme-colors-rgb: map-loop($cssColorVariables, to-rgb, "$value")  !default;

$cssColorContrastVariables: (
        "main-color-primary-red-contrast": colorTextContrast($main-color-primary-red),
        "main-color-primary-red-dark-contrast": colorTextContrast($main-color-primary-red),
        "main-color-primary-red-darken-contrast": colorTextContrast($main-color-primary-red),
        "main-color-primary-rost-contrast": colorTextContrast($main-color-primary-rost),
        "main-color-primary-rost-dark-contrast": colorTextContrast($main-color-primary-rost),
        "main-color-primary-rost-darken-contrast": colorTextContrast($main-color-primary-rost),
        "main-color-primary-beige-contrast": colorTextContrast($main-color-primary-beige),
        "main-color-primary-beige-dark-contrast": colorTextContrast($main-color-primary-beige),
        "main-color-primary-beige-darken-contrast": colorTextContrast($main-color-primary-beige),
        "main-color-primary-green-contrast": colorTextContrast($main-color-primary-green),
        "main-color-primary-green-dark-contrast": colorTextContrast($main-color-primary-green),
        "main-color-primary-green-darken-contrast": colorTextContrast($main-color-primary-green),
        "main-color-primary-cyan-contrast": colorTextContrast($main-color-primary-cyan),
        "main-color-primary-cyan-dark-contrast": colorTextContrast($main-color-primary-cyan),
        "main-color-primary-cyan-darken-contrast": colorTextContrast($main-color-primary-cyan),
        "main-color-primary-blue-contrast": colorTextContrast($main-color-primary-blue),
        "main-color-primary-blue-dark-contrast": colorTextContrast($main-color-primary-blue),
        "main-color-primary-blue-darken-contrast": colorTextContrast($main-color-primary-blue),
        "main-color-secondary-rost-pastel-contrast": colorTextContrast($main-color-secondary-rost-pastel),
        "main-color-secondary-beige-pastel-contrast": colorTextContrast($main-color-secondary-beige-pastel),
        "main-color-secondary-green-pastel-contrast": colorTextContrast($main-color-secondary-green-pastel),
        "main-color-secondary-blue-pastel": colorTextContrast($main-color-secondary-blue-pastel),
        "main-color-action-contrast": colorTextContrast($main-color-action),
        "main-color-danger-contrast": colorTextContrast($main-color-danger),
        "main-color-warning-contrast": colorTextContrast($main-color-warning),
        "main-color-success-contrast": colorTextContrast($main-color-success),
        "main-color-info-contrast": colorTextContrast($main-color-info)
);

$cssStyleVariables: (
        "main-font-family": "Inter,Helvetica, Arial, sans-serif",
        "main-font-weight": 400,
        "main-font-weight-bold": 600,
        "main-font-weight-light": 300,
        "main-font-size-base": 1.8rem,
        "main-font-size-small": 1.3rem,
        "main-font-size-big": 2rem,
        "main-line-height": 1.4,
        "main-link-color": $main-color-black,
        "main-link-color-hover": $main-color-black
);

:root {
  @each $key, $value in $cssColorVariables {
    --#{$key}: #{$value};
  }
  @each $key, $value in $cssColorContrastVariables {
    --#{$key}: #{$value};
  }
  @each $key, $value in $cssStyleVariables {
    --#{$key}: #{$value};
  }
}

$white: var(--main-color-white);
$black: var(--main-color-black);
$gray-base: var(--main-color-gray-base);
$middle-gray: var(--main-color-middle-gray);
$dark-gray: var(--main-color-dark-gray);
$light-gray: var(--main-color-light-gray);
$soft-gray: var(--main-color-soft-gray);
$primary: var(--main-color-primary-red);
$primary-red: var(--main-color-primary-red);
$primary-rost: var(--main-color-primary-rost);
$primary-beige: var(--main-color-primary-beige);
$primary-green: var(--main-color-primary-green);
$primary-cyan: var(--main-color-primary-cyan);
$primary-blue: var(--main-color-primary-blue);
$secondary: var(--main-color-secondary-rost-pastel);
$secondary-rost-pastel: var(--main-color-secondary-rost-pastel);
$secondary-beige-pastel: var(--main-color-secondary-beige-pastel);
$secondary-green-pastel: var(--main-color-secondary-green-pastel);
$secondary-blue-pastel: var(--main-color-secondary-blue-pastel);
$danger: var(--main-color-danger);
$warning: var(--main-color-warning);
$success: var(--main-color-success);
$action: var(--main-color-action);
$info: var(--main-color-info);

$primary-contrast: var(--main-color-primary-red-contrast);
$primary-red-contrast: var(--main-color-primary-red-contrast);
$primary-dark-red-contrast: var(--main-color-primary-red-dark-contrast);
$primary-darken-red-contrast: var(--main-color-primary-red-darken-contrast);
$secondary-contrast: var(--main-color-secondary-contrast);
$action-contrast: var(--main-color-action-contrast);
$danger-contrast: var(--main-color-danger-contrast);
$warning-contrast: var(--main-color-warning-contrast);
$success-contrast: var(--main-color-success-contrast);
$info-contrast: var(--main-color-info-contrast);

$body-background: var(--main-color-white);
$text-base: var(--main-color-black);

$backgroundColorScrollbar: $gray-base;
$backgroundColorScrollbarActive: $black;

//fonts

$font-family: var(--main-font-family);
$icon-family: "icons", Helvetica, Arial, sans-serif;

$font-size-base: var(--main-font-size-base);
$font-size-small: var(--main-font-size-small);
$font-size-big: var(--main-font-size-big);

$normal: var(--main-font-weight);
$bold: var(--main-font-weight-bold);
$light: var(--main-font-weight-light);

$line-height-base: var(--main-line-height);

$link: var(--main-link-color);
$link-hover: var(--main-link-color-hover);
$link-decoration: underline;
$link-decoration-hover: underline;

$headings-color: $text-base;
$headings-font-family: $font-family;
$headings-font-weight: $normal;
$headings-line-height: 1.3;
$headings-highlighted-font-weight: $normal;
$headings-highlighted-line-height: 1.18;

$h1-font-size: 5rem;
$h2-font-size: 2.4rem;
$h3-font-size: 2.4rem;
$h4-font-size: 1.8rem;
$h5-font-size: 1.7rem;
$h6-font-size: 1.7rem;

$h1-font-size-breakpoints: () !default;
$h2-font-size-breakpoints: () !default;
$h3-font-size-breakpoints: () !default;
$h4-font-size-breakpoints: () !default;
$h5-font-size-breakpoints: () !default;
$h6-font-size-breakpoints: () !default;
$highlighted-h1-breakpoints: () !default;
$highlighted-h2-breakpoints: () !default;
$highlighted-h3-breakpoints: () !default;

$h1-font-size-breakpoints: (
        xs: 2.4rem,
        sm: 3rem,
        md: 3.5rem,
        lg: $h1-font-size
);

$h2-font-size-breakpoints: (
        xs: 2rem,
        sm: 2.4rem,
        md: $h2-font-size
);

$h3-font-size-breakpoints: (
        xs: 1.8rem,
        sm: 2.2rem,
        md: $h2-font-size
);

$highlighted-h1-breakpoints: (
        xs: 3.2rem,
        md: 5rem,
        lg: 6rem,
        xlg: 7.2rem
);

$highlighted-h2-breakpoints: (
        xs: 2.8rem,
        md: 3.4rem,
        lg: 4.7rem
);

$h1-font-weight: $headings-font-weight;
$h2-font-weight: $headings-font-weight;
$h3-font-weight: $bold;
$h4-font-weight: $bold;
$h5-font-weight: $headings-font-weight;
$h6-font-weight: $headings-font-weight;

//spacers

$spacer-xsmall: 8px;
$spacer-base: 16px;
$spacer-small: 50px;
$spacer-large: 90px;
$spacer-xlarge: 130px;

$spacers: (
        0: 0,
        spacer-xsmall: $spacer-xsmall,
        spacer-base: $spacer-base,
        spacer-small: $spacer-small,
        spacer-large: $spacer-large,
        spacer-xlarge: $spacer-xlarge,
) !default;

$spacer-small-breakpoints: (
        xs: 16px,
        sm: 30px,
) !default;

$spacer-large-breakpoints: (
        xs: 20px,
        sm: 40px,
) !default;

$spacer-xlarge-breakpoints: (
        xs: 40px,
        sm: 80px,
) !default;

//grid

$grid-breakpoints: (
        xs: 0,
        sm: 768px,
        md: 1024px,
        lg: 1250px,
        xlg: 1540px
) !default;

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");

$screen-xs: 481px;
$screen-xs-min: $screen-xs;
$screen-phone: $screen-xs-min;

$screen-sm: 768px;
$screen-sm-min: $screen-sm;
$screen-tablet: $screen-sm-min;

$screen-md: 1024px;
$screen-md-min: $screen-md;
$screen-desktop: $screen-md-min;

$screen-lg: 1250px;
$screen-lg-min: $screen-lg;
$screen-lg-desktop: $screen-lg-min;

$screen-xlg: 1540px;
$screen-xlg-min: $screen-xlg;
$screen-xlg-desktop: $screen-lg-min;

$screen-xxs-max: ($screen-xs-min - 1);
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);
$screen-lg-max: ($screen-xlg-min - 1);

$screen-s9-max: 369px;
$screen-iphone-min: 374px;

$grid-columns: 12;
$grid-gap: 16px;
$grid-gap-small:8px;
$grid-gap-large: 64px;
$grid-gap-xlarge: 164px;

//containers

$container: 1772px;
$container-xsmall: 890px;
$container-small: 1200px;
$container-middle: 1474px;
$container-large: 1820px;

$zindexHeader: 100;
$zindexHeaderHovermenu: 101;
$zindexOverlay: 103;
$zindexMobileFilter: 104;
$zindexMobileCustomSelect: 104;
$zindexMobileCustomSelect: 104;
$zindexIconbarHover: 104;
$zindexMobileMenu: 105;
$zindexFullscreenGallery: 106;
$zindexModal: 107;
$zindexLoadingScreen: 108;
$zindexFlashmessages: 109;

$switchMobileMenu: $screen-sm-max;

//inputs

$inputPaddingTop: 14px;
$inputPaddingRight: 25px;
$inputPaddingBottom: 13px;
$inputPaddingLeft: $inputPaddingRight;
$inputLineHeight: 21px;
$inputFontSize: $font-size-base;

$inputPaddingTopXS: 10px;
$inputPaddingRightXS: 14px;
$inputPaddingBottomXS: 10px;
$inputPaddingLeftXS: $inputPaddingRightXS;
$inputLineHeightXS: 18px;
$inputFontSizeXS: 14px;

$inputPaddingTopXL: 20px;
$inputPaddingRightXL: 30px;
$inputPaddingBottomXL: 16px;
$inputPaddingLeftXL: $inputPaddingRightXL;
$inputLineHeightXL: 22px;
$inputFontSizeXL: $font-size-big;

$inputColor: $black;
$inputBackground: $white;

$inputBorderWidth: 1px;
$inputBorderColor: $black;
$inputBorderColorFocus: $black;
$inputBorderRadius: 0;

$inputMinHeight: $inputPaddingTop + $inputPaddingBottom + $inputLineHeight + $inputBorderWidth*2;
$inputMinHeightXS: $inputPaddingTopXS + $inputPaddingBottomXS + $inputLineHeightXS + $inputBorderWidth*2;
$inputMinHeightXL: $inputPaddingTopXL + $inputPaddingBottomXL + $inputLineHeightXL + $inputBorderWidth*2;

$inputGroupAddonWidth: $inputPaddingTop + $inputLineHeight + $inputPaddingBottom + $inputBorderWidth * 2;
$inputGroupAddonWidthXS: $inputPaddingTopXS + $inputLineHeightXS + $inputPaddingBottomXS + $inputBorderWidth * 2;
$inputGroupAddonIconFontSize: 18px;
$inputGroupAddonIconFontSizeXS:20px;

$labelLeftWidth: 140px;

//buttons

$buttonBackground: $light-gray;
$buttonBorderColor: $light-gray;
$buttonColor: $black;
$buttonBorderWidth: 1px;
$buttonBorderRadius: 0;
$buttonHoverScale: 1.3;

$buttonBackgroundHover: $primary-red;
$buttonBorderColorHover: $primary-red;
$buttonColorHover: $black;

$buttonBackgroundActive: $primary-red;
$buttonBorderColorActive: $primary-red;
$buttonColorActive: $primary-red-contrast;

$buttonPrimaryBackground: $black;
$buttonPrimaryBorderColor: $black;
$buttonPrimaryColor: $action-contrast;

$buttonPrimaryBackgroundHover: $primary-red;
$buttonPrimaryBorderColorHover: $primary-red;
$buttonPrimaryColorHover: $primary-red-contrast;

$buttonPrimaryBackgroundActive: $primary-red;
$buttonPrimaryBorderColorActive: $primary-red;
$buttonPrimaryColorActive: $primary-red-contrast;

$buttonSecondaryBackground: $white;
$buttonSecondaryBorderColor: $black;
$buttonSecondaryColor: $black;

$buttonSecondaryBackgroundHover: $white;
$buttonSecondaryBorderColorHover: $black;
$buttonSecondaryColorHover: $primary-red;

$buttonSecondaryBackgroundActive: $white;
$buttonSecondaryBorderColorActive: $primary-red;
$buttonSecondaryColorActive: $primary-red;

$buttonActionBackground: $black;
$buttonActionBorderColor: $black;
$buttonActionColor: $white;

$buttonActionBackgroundHover: $primary-red;
$buttonActionBorderColorHover: $primary-red;
$buttonActionColorHover: $primary-contrast;

$buttonActionBackgroundActive: $primary-red;
$buttonActionBorderColorActive: $primary-red;
$buttonActionColorActive: $primary-contrast;

//navigation
$mainnavColorLink: var(--main-color-text-base);
$mainnavColorLinkHover: $white;
$mainnavLinkBorderColor: $black;
$mainnavLinkHeightActive: 2px;
$mainnavLinkActiveColor: $black;
$mainnavFontSize: $font-size-base;
$mainnavFontSizeXLG: 1.7rem;
$mainnavSpaceBetweenLinks: 0px;
$mainnavSpaceBetweenLinksLG: 0px;
$mainnavFontWeightLevel1: $bold;

$navGroupColorLink: $text-base;
$navGroupColorLinkHover: $primary;
$navGroupSpaceBetween: 20px;
$navGroupSpaceLinksBetween: 15px;
$navGroupBorderColorMobile: $middle-gray;
$navGroupFontWeightLevel1: $bold;

$navColorLink: $text-base;
$navColorLinkHover: $primary;
$navSpaceBetweenXS: 20px;
$navSpaceBetween: 30px;

$megaMenuFontSizeBase: $font-size-base;
$megaMenuFontWeightBase: $normal;
$megaMenuFontSizeLevel1: $font-size-base;
$megaMenuFontWeightLevel1: $bold;

$subnavFontSizeBase: $font-size-base;
$subnavFontWeightBase: $normal;
$subnavFontSizeLevel1: $font-size-big;
$subnavFontWeightLevel1: $bold;
$subnavigatonLinkColor: $text-base;
$subnavigatonLinkColorHover: $primary;
$subnavigatonLinkColorActive: $primary;

$toggleNavigationBackgroundBar: $black;

// scss-docs-start position-map
$position-values: (
        0: 0,
        50: 50%,
        100: 100%
) !default;
// scss-docs-end position-map

// scss-docs-start border-variables
$border-width:                1px !default;
$border-widths: (
        1: 1px,
        2: 2px,
        3: 3px,
        4: 4px,
        5: 5px
) !default;
$border-style:                solid !default;
$border-color:                $gray-base !default;
$border-color-translucent:    rgba($black, .175) !default;
// scss-docs-end border-variables

//banner
$bannerHeadlineFontSizeBreakpoints: (
        xs: 3rem,
        md: 3.4rem,
        lg: 5rem
);
$bannerSublineFontSizeBreakpoints: (
        xs: 1.8rem,
        md: 2.4rem
);

//table
$tableBorderColor: transparent;

//collections
$mediaBoxBorderRadius: $inputBorderRadius;
$newsBoxBorderRadius: $inputBorderRadius;

//alerts
$alert-colors: (
        "primary": $primary,
        "secondary": $secondary,
        "action": $action,
        "danger": $danger,
        "warning": $warning,
        "success": $success
);

//breadcrumb
$breadcrumbFontSize: $font-size-small;
$breadcrumbLinkColor: $dark-gray;
$breadcrumbLinkColorHover: $primary;
$breadcrumbLinkColorCurrent: $black;
$breadcrumbLinkFontWeightCurrent: $normal;
$breadcrumbBreakScrollable: $screen-sm-max;
$breadcrumbBreakScrollableMin: $screen-md;

//contentbox
$contentBoxBorderRadius: $inputBorderRadius;
$contentBoxInBoxHeadlineFontSizeBreakpoints: (
        xs: 1.6rem,
        sm: 2rem,
        md: 2.6rem,
        lg: 3rem
);

//fullscreenGallery
$fullscreenGalleryThumbs: 80px;
$fullscreenGalleryThumbsMobil: 60px;

//newsletter
$nlFooterFormButtonColor: $white;
$nlFooterFormButtonBackground: $black;
$nlFooterFormButtonBorderColor: $black;

//scrollbar
$scrollbarNavButtonWidthHeight: 40px;
$scrollbarBreakpointArrowsOutside: $container;

//slidecontent
$slidecontentBorderColor: $inputBorderColor;

//slideshow
$slideshowNavButtonWidthHeight: 34px;
$slideshowBreakpointArrowsOutside: $container;

//flashmessages
$fontSizeFlashMessage: $font-size-small;
$widthFlashMessage: 400px;

//modals
$icon-modal-success: $i-check;
$icon-modal-danger: $i-exclamation;
$icon-modal-warning: $i-exclamation;
$modalBorderRadius: 0;
$modalBackground: fade_out(#333333, 0.5);

//overlay
$overlayBackground: rgba(255, 255, 255, .7);

//pagination
$paginationColor: $light-gray;
$paginationColorActive: $black;
$paginationBackground: transparent;
$paginationBackgroundActive: transparent;
$paginationLinkColor: $light-gray;
$paginationLinkColorHover: $black;
$paginationLinkBackgroundHover: transparent;

//processbar
$processbarColor: $middle-gray;
$processbarColorActive: $text-base;
$processbarItemWidth: 200px;

//searchbar
$searchBarBackground: $white;
$searchBarBorderColor: $black;

//tabs
$tabsBorderColor: $inputBorderColor;

//tags
$tagsBackground: $light-gray;
$tagsColor: $text-base;
$tagsBackgroundHover: $primary;
$tagsColorHover: $white;
$tagsBackgroundActive: darken($main-color-primary-red,5);
$tagsColorActive: $white;

//accountBox
$accountBoxBorderRadius: $inputBorderRadius;
$accountBoxBorderColor: $inputBorderColor;
$accountBoxActionColor: $dark-gray;
$accountBoxActionHoverColor: $black;

//categoryBox
$categoryBoxHeadlineFontSizeBreakpoints: (
        xs: 1.8rem,
        sm: 2.1rem,
        md: 2.1rem,
        lg: 2.1rem
);
$categoryBoxBackground: $primary-rost;
$categoryBoxColor: $white;
$categoryBoxBackgroundHover: darken($main-color-primary-rost,10);
$categoryBoxColorHover: $white;

//categoryList
$categoryHeadlineFontSizeBreakpoints: (
        xs: 1.6rem,
        sm: 2rem,
        md: 2.6rem,
        lg: 3rem
);

//categoryItempreview
$categoryItempreviewColor: $text-base;
$categoryItempreviewColorHover: $text-base;
$categoryItempreviewHeadlineColorHover: $primary;
$categoryItempreviewBorderColor: $inputBorderColor;
$categoryItempreviewBorderColorActive: $inputBorderColorFocus;

//documentarchive
$documentArchiveBorderColor: $inputBorderColor;
$documentArchiveWidthCell: 120px;
$documentArchiveWidthCellLG: 180px;

//filter
$filterSwitchMobile: $screen-sm-max;

//itemcardImages
$itemcardImagesMaxWidth: 800px;
$itemcardImagesThumbsBorderColor: transparent;
$itemcardImagesThumbsBorderColorActive: transparent;
$itemcardImagesThumbsWidth: 165px;
$itemcardImagesThumbsWidthBreakpoint: 63px;
$itemcardImagesDirectionBreakpoint: $screen-sm-max;
$itemcardImagesThumbsMobileHide: false;

//itemcard VariantSelection
$variantSelectionBorderColor: $inputBorderColor;
$variantSelectionBorderColorHover: $inputBorderColorFocus;
$variantSelectionBorderColorActive: $black;
$variantSelectionBorderRadius: $inputBorderRadius;
$variantSelectionWidth: 70px;

//itemlist
$itemlistMediaWidthXS: 60px;
$itemtableListBorderColor: $inputBorderColor;

$itemBoxListGap: $grid-gap-small;
$itemBoxListGapBetweenRows: 40px;
$itemBoxFontSize: $font-size-small;
$itemBoxFontSizeXS: calc($font-size-small * 0.9);

$itemBoxBreakpointsWidth: (
        xs: 130px,
        sm: 200px,
        md: 200px,
        lg: 250px,
        xlg: 250px,
);

$itemBoxSliderContainer: $container;
$itemBoxSliderGap: $grid-gap-small;
$itemBoxSliderCalcPadding: calc($grid-gap / 2 - $itemBoxSliderGap / 2);

$itemBasketBoxBorderColor: $middle-gray;

$itemcardColumnGap: $grid-gap;
$itemcardColumnGapLarge: $grid-gap-large;
$itemcardRowGap: $grid-gap;
$itemcardRowGapLarge: $grid-gap;

//item statistic
$statisticsItemBorderColor: $middle-gray;

//favorites
$favoritesIconInactive: $i-heart;
$favoritesIconActive: $i-heart-filled;

//quantity
$quantityBorderColor: $inputBorderColor;
$quantityWidth: 90px;
$quantityWidthSmall: 110px;

//order
$orderHeadlineFontSize: 1.6rem;
$orderHeadlineFontWeight: $bold;
$orderHeadlineFontColor: $text-base;

$orderQuantityButtonWidth: 90px;

$orderItemBorderColor: $middle-gray;

$orderItemBorderColor: $middle-gray;

//shipmentAdressList
$shipmentAddressListGap: $grid-gap-small;
$shipmentAddressListItemWidth: 270px;

//utilities
$utilities-colors: $theme-colors-rgb !default;
$enable-important-utilities: false;
$rfs-mq-value: 800px;
$rfs-breakpoint: 1200px;
$variable-prefix:             dc- !default; // Deprecated in v5.2.0 for the shorter `$prefix`
$prefix:                      $variable-prefix !default;

// scss-docs-start utilities-border-colors
$utilities-border: map-merge(
                $utilities-colors,
                (
                        "black": to-rgb(#000000),
                        "white": to-rgb(#FFFFFF)
                )
) !default;
$utilities-border-colors: map-loop($utilities-border, rgba-css-var, "$key", "border") !default;

$utilities-border-subtle: (
        "primary-subtle": var(--#{$prefix}primary-border-subtle),
        "secondary-subtle": var(--#{$prefix}secondary-border-subtle),
        "success-subtle": var(--#{$prefix}success-border-subtle),
        "info-subtle": var(--#{$prefix}info-border-subtle),
        "warning-subtle": var(--#{$prefix}warning-border-subtle),
        "danger-subtle": var(--#{$prefix}danger-border-subtle),
        "light-subtle": var(--#{$prefix}light-border-subtle),
        "dark-subtle": var(--#{$prefix}dark-border-subtle)
) !default;
// scss-docs-end utilities-border-colors

// scss-docs-start box-shadow-variables
$box-shadow:                  0 0 40px rgba(0, 0, 0, 0.08) !default;
$box-shadow-sm:               0 0 60px rgba(0, 0, 0, 0.08) !default;
$box-shadow-lg:               0 0 80px rgba(0, 0, 0, 0.08) !default;
$box-shadow-inset:            inset 0 0 40px rgba(0, 0, 0, 0.08) !default;

$iconbarIconWidth: 40px;
$iconbarIconHeight: 40px;
$iconbarIconFontSize: 18px;
$iconbarIconLabelFontSize: 1.6rem;
$iconbarIconColor: var(--main-color-text-base);
$iconbarIconColorHover: var(--main-color-primary-red);
$iconbarSpaceBetweenIcons: 20px;
$iconbarIconCountColor: $primary-contrast;
$iconbarIconCountBackground: var(--main-color-primary-red);
$iconbarBadgeWidth: 30px;
$iconbarBadgeHeight: 30px;
$iconbarBadgeBackgroundColor: $main-color-middle-gray;
$iconbarBadgeColor: colorTextContrast($main-color-middle-gray);

$showIconLabelBreakpoint: $screen-md;