/*
Containers

Markup:
<div class="container {{modifier_class}}"><div class="box">Lorem ipsum</div></div>

.container - Base Container
.container--small - Small Container
.container--large - Large Container
.container--full - Container Maxwidth Full-HD


Styleguide Structure.Containers
*/

$paddingContainer: calc($grid-gap / 2);

.container,
.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;

  @if($paddingContainer > 20){
    padding-right: $paddingContainer;
    padding-left: $paddingContainer;
  } @else {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.container {
  max-width: $container;
}

.container--xsmall {
  max-width: $container-xsmall;
}

.container--small {
  max-width: $container-small;
}

.container--large {
  max-width: $container-large;
}

.container--full {
  max-width: 1920px;
  padding-left: 0;
  padding-right: 0;
}