.headerMain {
  padding-block: $spacer-base;
  > .container {
    display: flex;
    align-content: center;
    justify-content: space-between;
  }
}

.headerMainLeft {
  display: flex;
  align-items: center;
  gap: 95px;
}

.headerMainRight {
  display: flex;
  align-items: center;

  @media(min-width: $screen-md) {
    gap: $spacer-base*3;
  }
}

.headerLanguage {
  margin-right: $spacer-base;
}