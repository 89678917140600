/*
Text

Markup:
<span class="{{modifier_class}}"><strong>Lorem ipsum dolor sit amet</strong>, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</span>

.leadtext - Bigger Text
.smalltext - Smaller Text

Styleguide Typo.Text
*/
/*
Links

Markup:
<a href="#" class="{{modifier_class}}">Link</a>

:hover - Hover
:focus - Focus
:active - Active

Styleguide Typo.Links
*/

html {
  font-size: 9px;

  @media (min-width: $screen-md) {
    font-size: 10px;
  }
}

body {
  background-color: $body-background;
  font-size: $font-size-base;
  font-weight: $normal;
  line-height: $line-height-base;
  font-family: $font-family;
  color: $text-base;
  min-width: 320px;
}

.leadtext {
  font-size: calc($font-size-base + .2rem);
}

.smalltext {
  font-size: calc($font-size-base - .2rem);
}

%linkHover {
  color: $link-hover;
  text-decoration: $link-decoration-hover;
  outline: 0;
}

a, .link {
  color: $link;
  text-decoration: $link-decoration;
  text-underline-offset: 5px;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    color: $link;
    text-decoration-color: $primary-red;
  }
}

* {
  &:focus {
    outline: 0;
  }
}

a:focus {
  outline: 0;
}

b, strong {
  font-weight: $bold;
}

label {
  font-size: $font-size-small;
  display: inline-block;
}

hr {
  border: 0;
  border-top: 1px solid $light-gray;
  margin-top: 30px;
  margin-bottom: 30px;
}

.text-decoration-underline {
  text-decoration: underline;
}

p {
  margin-top: 0;
  margin-bottom: 1em;
}

small {
  font-size: $font-size-small;
}

.link-break {
  overflow: hidden;
  word-break: break-all;
}

.text-sm--end {
  @media(min-width: $screen-lg) {
      text-align: right;
  }
}