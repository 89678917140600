/*
Custom Select

Markup:
<div class="customSelect js-customSelect {{modifier_class}}">
  <select>
    <option disabled selected>{{tc.please_choose}}</option>
    <option value="s">S</option>
    <option value="m">M</option>
    <option value="l">L</option>
    <option value="xl" disabled>XL</option>
    <option value="xxl">XXL</option>
  </select>
  <div class="customSelect__selected">Choose</div>
  <div class="customSelect__dropdown">
    <div class="customSelect__dropdownClose">
        <i class="icon icon-close"></i>
    </div>
    <div class="customSelect__dropdownHeader d-block d-sm-none">
        <div class="customSelect__dropdownTitle">
            Title
        </div>
    </div>
    <div class="customSelect__dropdownItem" data-value="s">
      <div class="customSelect__dropdownItemHeadline">S</div>
    </div>
    <div class="customSelect__dropdownItem" data-value="m">
      <div class="customSelect__dropdownItemHeadline">M</div>
    </div>
    <div class="customSelect__dropdownItem" data-value="l">
      <div class="customSelect__dropdownItemHeadline">L</div>
    </div>
    <div class="customSelect__dropdownItem disabled" data-value="xl">
      <div class="customSelect__dropdownItemHeadline">XL</div>
      <div class="customSelect__dropdownItemText">Not available</div>
    </div>
    <div class="customSelect__dropdownItem" data-value="xxl">
      <div class="customSelect__dropdownItemHeadline">XL</div>
    </div>
  </div>
</div>

.is-loading - is loading
.is-success - is success
.is-error - is error
.customSelect--small - Small Custom Select
.customSelect--small.is-loading - Small Custom Select is loading
.customSelect--small.is-success - Small Custom Select is success
.customSelect--small.is-error - Small Custom Select is error

Styleguide Components.Custom Select
*/

.customSelect {
  position: relative;
  width: 100%;

  &__selectedCount {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: $primary;
    color: var(--main-color-primary-contrast);
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    font-size: 1.4rem;
    vertical-align: middle;
    margin-top: -2px;
    display: none;

    &.is-active {
      display: inline-flex;
    }
  }

  &__selected {
    @extend %inputHelper;
    @extend .selectBody;
    padding-right: 40px;
  }

  &__hiddenFormField {
    display: none;
  }

  &__dropdown {
    z-index: 4;
    background: $white;
    font-size: $font-size-small;
    overflow: hidden;
    flex-direction: column;

    @media (max-width: $screen-xs-max) {
      box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
      padding: 10px;
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      translate: 0 110%;
      transition: 0.2s ease-in-out;
      pointer-events: none;
      opacity: 0;
      z-index: $zindexMobileCustomSelect;
      max-height: 100vh;
      max-height: 100svh;
    }

    @media (min-width: $screen-sm) {
      position: absolute;
      left: 0;
      top: 100%;
      width: 100%;
      min-width: 300px;
      max-width: 100vw;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
      border: $inputBorderWidth solid $inputBorderColor;
      border-radius: $inputBorderRadius;
      margin-top: 5px;
      display: none;
    }
  }

  &__dropdownClose {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1;
    @media (min-width: $screen-sm) {
      display: none;
    }
  }

  &__dropdownTitle {
    font-weight: $bold;
    padding-right: 40px;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 1.8rem;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    @media (min-width: $screen-sm) {
      display: none;
    }
  }

  &__dropdownHeader {
    padding: 10px;
    margin-bottom: 10px;
  }

  &__dropdownBody {
    overflow: hidden;
    overflow-y: auto;
    @media (min-width: $screen-sm){
      max-height: 260px;
    }
  }

  &__dropdownFooter {
    margin-top: 10px;
    padding: 10px;
    padding-bottom: 15px;
    border-top: 1px solid $light-gray;
    text-align: center;
    @media (max-width: $screen-xs-max) {
      padding-bottom: 0;
    }

    .button {
      width: 100%;
      margin-bottom: 10px;
    }

    .linkWithIcon {
      font-size: $font-size-small;
    }
  }

  &__dropdownItemHeadline {
    font-weight: $bold;
  }

  &__dropdownItemText {
    font-size: 0.9em;
    color: $dark-gray;
    padding-left: 20px;
  }

  &__dropdownItem {
    padding: 10px 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    color: $text-base;
    text-decoration: none;

    &:hover {
      background: $light-gray;
      color: $text-base;
      text-decoration: none;
    }

    &.is-active {
      background: $light-gray;
    }

    &.disabled {
      pointer-events: none;
      cursor: not-allowed;
      color: $middle-gray;

      .customSelect__dropdownItemText {
        color: $middle-gray;
      }
    }

    &--radio,
    &--checkbox {
      padding-left: 40px;

      &:before,
      &:after {
        content: "";
        position: absolute;
        left: 10px;
        top: 50%;
        translate: 0 -50%;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:before {
        border: $inputBorderWidth solid $inputBorderColor;
        border-radius: $inputBorderRadius;
        background: $white;
      }

      &:after {
        opacity: 0;
      }

      &.is-active {
        &:before {
          border-color: $black;
        }

        &:after {
          opacity: 1;
        }
      }
    }

    &--radio {
      &:before,
      &:after {
        border-radius: 50%;
      }

      &:after {
        background: $black;
        width: 12px;
        height: 12px;
        margin-left: 4px;
        opacity: 0;
      }
    }

    &--checkbox {
      &:after {
        content: $i-check;
        font-family: $icon-family;
        font-size: 18px;
      }

      &.is-active {
        background: transparent;

        &:before {
          border-color: $black;
        }

        &:after {
          opacity: 1;
        }
      }
    }
  }

  select {
    display: none;
  }

  &.is-active {
    .customSelect__selected {
      border-color: $primary;

      &:after {
        content: $i-angle-up;
      }
    }

    .customSelect__dropdown {
      display: flex;

      @media (max-width: $screen-xs-max) {
        translate: 0 0;
        pointer-events: auto;
        opacity: 1;
      }
    }
  }

  &.is-loading,
  &.is-success,
  &.is-error {
    .customSelect__selected {
      background: $middle-gray !important;
      border-color: $middle-gray !important;
      cursor: not-allowed;
      pointer-events: none;
      padding-right: 40px;
      padding-left: 10px;
      position: relative;

      .icon {
        opacity: 0;
      }

      &:after {
        position: absolute;
        right: 15px;
        top: 50%;
        font-family: $icon-family;
        transform: translateY(0);
        translate: 0 -50%;
        font-size: 24px;
        margin-top: 0;
      }
    }
  }

  &.is-loading {
    .customSelect__selected {
      cursor: wait;
      color: $black !important;

      &:after {
        content: $i-loading;
        opacity: 1;
        animation: rotation 1s infinite ease-in-out;
      }
    }
  }

  &.is-success {
    .customSelect__selected {
      background: $success !important;
      border-color: $success !important;
      color: $black !important;

      &:after {
        content: $i-check;
        animation: scaleRequestIconBtn 1s forwards ease-in-out;
      }
    }
  }

  &.is-error {
    .customSelect__selected {
      background: $danger !important;
      border-color: $danger !important;
      color: $white !important;

      &:after {
        content: $i-close;
        animation: scaleRequestIconBtn 1s forwards ease-in-out;
      }
    }
  }

  &--small {
    width: auto;
    display: inline-flex;

    .customSelect__selected {
      padding: 0;
      padding-right: 20px;
      background: transparent;
      border: 0;
      border-radius: 0;

      &:after {
        font-size: 1.2rem;
        right: 0;
      }
    }

    &.is-loading,
    &.is-success,
    &.is-error {
      .customSelect__selected {
        padding-right: 20px;
        padding-left: 0;

        &:after {
          right: 0;
          font-size: 16px;
        }
      }
    }

    &.is-loading {
      .customSelect__selected {
        cursor: wait;
        background: transparent !important;
        border-color: transparent !important;
        color: $black !important;
      }
    }

    &.is-success {
      .customSelect__selected {
        background: transparent !important;
        border-color: transparent !important;
        color: $success !important;
      }
    }

    &.is-error {
      .customSelect__selected {
        background: transparent !important;
        border-color: transparent !important;
        color: $danger !important;
      }
    }
  }

  &--openRight {
    .customSelect__dropdown {
      left: auto;
      right: 0;
    }
  }

  &--openTop {
    .customSelect__dropdown {
      top: auto;
      bottom: 35px;
    }
  }

  &__article {
    @media (min-width: $screen-xs-max) {
      .customSelect__dropdown {

        position: relative;
        display: block;
        top: unset;
        left: unset;
        min-width: unset;
        border: unset;
        background: none;
        box-shadow: none;
      }

      .customSelect__selected {
        display: none;
      }

      .inputGroup {
        flex-direction: column;
      }

      .customSelect__dropdownFooter {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 0;
        border-bottom: 1px solid $light-gray;
        margin-bottom: $spacer-base;
        padding: 0;
        padding-bottom: $spacer-base;
        .button {
          width: auto;
          margin-bottom: 0;
          @media (max-width: $screen-xs-max) {
            margin-bottom: $spacer-base;
          }
        }
      }

      .customSelect__dropdownItemHeadline {
        font-weight: $normal;
      }

      .customSelect__dropdownItem--checkbox {
        padding-left: 30px;
        &:before,
        &:after {
          left: 0;
        }
      }
    }
  }
}
