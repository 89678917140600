/*
Action Icon

Markup:
<button class="actionIcon {{modifier_class}}"><i class="icon icon-favorites"></i></button>

:hover - Hover
:focus - Focus
.is-loading - Loading
.is-success - Success
.is-error - Error

Styleguide Components.Action Icon
*/

.actionIcon {
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
  color: $text-base;
  text-decoration: none;
  position: relative;
  border: 0;
  background: transparent;
  border-radius: 50%;
  padding: 0;
  margin: 0;
  @media(min-width: $screen-md) {
    width: 44px;
    height: 44px;
    font-size: 24px;
  }
  .icon {
    transition: scale 200ms ease-in;
  }



  &:hover {
    background: $white!important;
    text-decoration: none;
    color: $text-base;

    .icon {
      scale: 1.1;
    }

    .icon-favorites:before {
      content: $favoritesIconInactive;
    }

    .icon-favorites-filled:before {
      content: $favoritesIconActive;
    }
  }

  &:focus, &:active {
    background: $white;
    text-decoration: none;
    color: $text-base;
  }

  &:before {
    display: none;
  }

  &:after {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $icon-family;
    content: "";
    transition: 0.2s ease-in-out;
    opacity: 0;
    position: absolute;
  }

  .icon {
    opacity: 1;
  }

  &.is-loading,
  &.is-success,
  &.is-error {
    color: $middle-gray !important;
    cursor: not-allowed;
    padding: 0;
    background: transparent !important;

    .icon {
      opacity: 0;
    }
  }

  &.is-loading {
    cursor: wait;

    &:after {
      content: $i-loading;
      opacity: 1;
      animation: rotation 1s infinite ease-in-out;
    }
  }

  &.is-success {
    color: $success !important;

    &:after {
      content: $i-check;
      animation: scaleRequestIcon 1s forwards ease-in-out;
    }
  }

  &.is-error {
    color: $danger !important;

    &:after {
      content: $i-close;
      animation: scaleRequestIcon 1s forwards ease-in-out;
    }
  }

  &.active {
    color: $primary;
    background: transparent;
  }

}