$spacer-between-form-groups: $spacer-base;
$spacer-between-form-groups-xs: $spacer-base;
.jotform-form {
  font-family: $font-family!important;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px $white inset !important;
  }

  input,
  select,
  textarea,
  %inputHelper {
    background-color: $inputBackground!important;
    color: $inputColor!important;
    border: $inputBorderWidth solid $inputBorderColor!important;
    border-radius: $inputBorderRadius!important;
    box-shadow: none;
    display: block;
    width: 100%;
    line-height: $inputLineHeightXS;
    font-size: clamp(16px, $inputFontSizeXS, 20px);
    padding: $inputPaddingTopXS $inputPaddingRightXS $inputPaddingBottomXS $inputPaddingLeftXS;

    @media (min-width: $screen-sm) {
      line-height: $inputLineHeight;
      font-size: $inputFontSize;
      padding: $inputPaddingTop $inputPaddingRight $inputPaddingBottom $inputPaddingLeft;
    }

    &:not(:disabled) {
      &:active, &:focus {
        outline: 0;
        border-color: $inputBorderColorFocus!important;
      }
    }

    &.error {
      border-color: $danger;
    }

    &:disabled {
      background-color: $white;
      border-color: $light-gray;
      color: $light-gray;
    }
  }

  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=submit] {
    width: auto;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  input[type="file"] {
    & + .full {
      flex-grow: 1;
      padding-left: 15px;
      padding-right: 15px !important;
      display: flex;
      align-items: center;
      justify-content: space-between;

      i {
        font-size: 18px;
      }
    }

    &:hover {
      & + .full {
        i {
          color: $primary;
        }
      }
    }
  }

  textarea {
    resize: vertical;
  }

  %selectBodyAfter {
    content: $i-angle-down;
    font-family: $icon-family;
    position: absolute;
    right: $spacer-base*1.5;
    top: 50%;
    font-size: 18px;
    pointer-events: none;
    transform: translateY(-50%);
    margin-top: -1px;
  }

  .selectBody {
    background-color: $inputBackground;
    cursor: pointer;
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:after {
      @extend %selectBodyAfter;
    }

    select {
      color: $inputColor;
      border-color: $inputBorderColor;
      cursor: pointer;
      padding-right: 40px;
      appearance: none;
      display: block;
      width: 100%;

      &::-ms-expand {
        display: none;
      }
    }

    &.focus {
      select {
        border-color: $inputBorderColorFocus;
      }
    }

    &.disabled {
      select {
        cursor: default;
      }
    }
  }

  form {
    margin:0;
  }
  ul.form-section {
    padding:0;
    list-style: none;
    li {
      &:before{
        display: none;
      }
    }
  }

  .form-dropdown {
    position: relative;
    &:after {
      @extend %selectBodyAfter;
    }
  }

  .form-section {
    margin-bottom: $spacer-between-form-groups;
    input,
    textarea,
    .select_body {
      width: 100%;
    }
    &.has-danger {
      input {
        border-color: $danger;
        color: $danger;
      }
    }
    label {
      font-size: 1.3rem;
      margin-bottom: 5px!important;

      &.form-sub-label {
        margin-top: 5px;
        color: $gray-base;
      }
    }

    @media (max-width: $screen-xs-max ) {
      margin-bottom: $spacer-between-form-groups-xs;
    }
  }

  .form-all {
    box-shadow: none;
    border-radius: 0;
    background: transparent;
    margin:0;
    max-width: none;
  }

  .form-label-top {
    margin-bottom: 5px!important;
  }

  .form-line {
    padding:0;
    margin-bottom: 10px!important;
  }

  .form-buttons-wrapper, .form-submit-clear-wrapper {
    margin:0;
    padding:0;
    border:0;
    justify-content: flex-start;
    padding-inline: 4px!important;
  }

  .form-dropdown,
  .form-textarea,
  .form-textbox,
  .signature-pad-passive,
  .signature-wrapper {
    height: auto!important;
    border-radius: 0!important;
    &:hover,
    &:focus {
      box-shadow: none !important;
      border-color: $primary;
    }
  }

  .form-textarea {
    min-height: 200px;
  }

  .jf-form-buttons:not(.form-pagebreak-back) {
    margin-left: 0;
  }

  .formFooter-button, .submit-button {
    display: inline-block;
    vertical-align: middle;
    border: 1px solid $buttonBorderColor;
    border-radius: $buttonBorderRadius;
    color: $buttonColor;
    cursor: pointer;
    background: $buttonBackground;
    text-align: center;
    text-decoration: none;
    line-height: $inputLineHeightXS;
    font-size: $inputFontSizeXS;
    padding: $inputPaddingTopXS $inputPaddingRightXS $inputPaddingBottomXS $inputPaddingLeftXS;
    transition: ease-in-out 0.1s;
    position: relative;
  }

  .form-checkbox+label:before, .form-checkbox+span:before, .form-radio+label:before, .form-radio+span:before {
    border-color: $black;
    box-shadow: none !important;
  }

  .form-checkbox:checked+label:before, .form-checkbox:checked+span:before, .form-checkbox:checked+span label:before {
    border-color: $black;
    background: $black;
  }
}


.jotform__linkFix {
  a {
    text-decoration: $link-decoration;
  }
}