
img,
svg {
  height: auto !important;
  max-width: 100%;
  object-fit: contain;
}

.image {
  max-width: 100%;
  width: 100%;
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  > video,
  > img,
  > svg {
    object-fit: contain;
    object-position: center center;
    max-height: 100% !important;
    aspect-ratio: 1;
    width: 100%;
  }
}

.imageCover {
  @extend .image;

  > video,
  > img,
  > svg {
    object-fit: cover;
    min-width: 100%;
  }
}

.imageRound {
  border-radius: 50%;
  overflow: hidden;

  > img {
    min-width: 100%;
    object-fit: cover;
  }
}

%gradientOverlay {
  position: relative;
  overflow: hidden;

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(0, rgba(0, 0, 0, 0.3) 0%, rgba(255, 255, 255, 0) 100%);
    position: absolute;
    left: 0;
    bottom: 0;
    pointer-events: none;
  }
}

%gradientOverlayFull {
  position: relative;
  overflow: hidden;

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    left: 0;
    bottom: 0;
  }
}