/* public-sans-regular - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/inter-v13-latin-regular.woff2') format('woff2') /* Chrome 26+, Opera 23+, Firefox 39+ */
}

/* public-sans-600 - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: bold;
  src: url('../fonts/inter-v13-latin-600.woff2') format('woff2') /* Chrome 26+, Opera 23+, Firefox 39+ */
}

/* public-sans-600 - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url('../fonts/inter-v13-latin-300.woff2') format('woff2') /* Chrome 26+, Opera 23+, Firefox 39+ */
}