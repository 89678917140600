
.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

$visibilityClasses: (
        none,
        block,
        inline,
        inline-block,
        flex,
        inline-flex
);

@each $value in $visibilityClasses {
  .d-#{$value} {
    display: $value !important;
  }
}

//$grid-breakpoints

@each $breakpoint, $size in $grid-breakpoints {
  @each $value in $visibilityClasses {
    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
      .d-#{$breakpoint}-#{$value} {
        display: $value !important;
      }
    }
  }
}