@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes scaleRequestIcon {
  0% {
    opacity: 0;
    scale: 0
  }
  50% {
    opacity: 1;
    scale: 1.2
  }
  100% {
    opacity: 1;
    scale: 1
  }
}

@keyframes scaleRequestIconBigger {
  0% {
    opacity: 0;
    scale: 0
  }
  50% {
    opacity: 1;
    scale: 1.6
  }
  100% {
    opacity: 1;
    scale: 1
  }
}