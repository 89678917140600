.animatedText {
  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6, .textcontent {
    color: $black;
  }
  position: relative;
  padding-block: $spacer-base*2;
  &:before, &:after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 400px;
    height: 1px;
    background: $primary-rost;
    max-width: 80%;
  }
  &:before {
    top: 0;
  }
  &:after {
    bottom: 0;
  }

  .textcontent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  span {
    transition: color .2s ease-in-out;
    font-size: 2rem;
    line-height: 1.1;
    color: $light-gray;
    @media(min-width: $screen-sm) {
      font-size: 3rem;
    }
    @media(min-width: $screen-md) {
      font-size: 4rem;
    }

    &.active {
      color: $black;
    }
  }
}