.navgroup {
  margin-bottom: 0;
  > ul {
    display: flex;
    align-items: center;
    > li {
      > a {
        padding-block: 20px;
        padding-inline: 15px;
        font-weight: $normal;
        margin-bottom: 0;
        font-size: 1.6rem;
        @media (min-width: $screen-lg) {
          padding-inline: 20px;
          font-size: $font-size-base;
        }
        @media (min-width: $screen-xlg) {
          padding-inline: 30px;
        }
      }
      &:last-child {
        &:not(:only-child) {
          > a {
            padding-right: 0;
          }
        }

      }
    }
  }
}

.headerNavigation {
  .navgroup {
    > ul {
      > li {
        &:hover, &:active, &:focus, &.hoverintent, &.is-active, &.is-active-tree {
          > a {
            position: relative;
            color: $black;
            &:before {
              content: "";
              position: absolute;
              left: 0;
              top: 50%;
              translate: 0 -50%;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background: $primary-red;
              @media(min-width: $screen-xlg) {
                left: 15px;
              }
            }
          }
        }
      }
    }
  }
}