// Grid system
//
// Generate semantic grid columns with these mixins.

@mixin make-row($gutter: $grid-gap) {
  display: flex;
  flex-wrap: wrap;
  margin-right: calc(-1 * $grid-gap / 2);
  margin-left: calc(-1 * $grid-gap / 2);
}

@mixin make-col-ready($gutter: $grid-gap) {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc($gutter / 2);
  padding-left: calc($gutter / 2);
}

@mixin make-col($size: false, $columns: $grid-columns) {
  @if $size {
    flex: 0 0 auto;
    width: percentage(calc($size / $columns));

  } @else {
    flex: 1 1 0;
    max-width: 100%;
  }
}

@mixin make-col-auto() {
  flex: 0 0 auto;
  width: auto;
}

@mixin make-col-offset($size, $columns: $grid-columns) {
  $num: calc($size / $columns);
  margin-left: if($num == 0, 0, percentage($num));
}

// Row columns
//
// Specify on a parent element(e.g., .row) to force immediate children into NN
// number of columns. Supports wrapping to new lines, but does not do a Masonry
// style grid.
@mixin row-cols($count) {
  > * {
    flex: 0 0 auto;
    width: divide(100%, $count);
  }
}

@mixin make-grid-columns($columns: $grid-columns, $gutter: $grid-gap, $breakpoints: $grid-breakpoints) {
  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    @include media-breakpoint-up($breakpoint, $breakpoints) {
      // Provide basic `.col-{bp}` classes for equal-width flexbox columns
      .col#{$infix} {
        flex: 1 0 0%; // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
      }

      .col#{$infix}-auto {
        @include make-col-auto();
      }

      @if $columns > 0 {
        @for $i from 1 through $columns {
          .col#{$infix}-#{$i} {
            @include make-col($i, $columns);
          }
        }

        // `$columns - 1` because offsetting by the width of an entire row isn't possible
        @for $i from 0 through ($columns - 1) {
          @if not ($infix == "" and $i == 0) { // Avoid emitting useless .offset-0
            .offset#{$infix}-#{$i} {
              @include make-col-offset($i, $columns);
            }
          }
        }
      }
    }
  }
}
