

.slidecontentItem {
  border-top: 1px solid $slidecontentBorderColor;
  border-bottom: none;

  &:last-child {
    border-bottom: 1px solid $slidecontentBorderColor;
  }

  &__headline {
    position: relative;
    font-size: 1.8rem;
    color: $text-base;
    padding: 25px 30px 21px 0;
    cursor: pointer;

    &:after {
      content: $i-angle-down;
      font-family: $icon-family;
      position: absolute;
      left: 0;
      top: 50%;
      font-size: 20px;
      width: 20px;
      text-align: center;
      transition: 0.2s ease-in-out;
      translate: 0 -50%;
    }

    &:hover, &:active, &:focus {
      color: $primary;
    }
  }

  &__content {
    display: none;
    padding: 10px 10px 20px 30px;
  }

  &.is-active {
    .slidecontentItem__headline:after {
      rotate: 180deg;
    }
  }
}

.slidecontentItem {
  border-top: 1px solid $slidecontentBorderColor;
  border-bottom: none ;
  border-bottom: none;
  &__headline {
    padding: 21px 30px 21px 0;
    &:after {
      right: 0;
      left: auto;
    }

    &:hover, &:active, &:focus {
      color: $black;
      &:after {
        color: $primary-red;
      }
    }
  }

  &__content {
    padding: 0 30px 20px 0;
    font-size: 1.6rem;
  }

  &:last-child {
    border-bottom: 1px solid $slidecontentBorderColor;
    margin-bottom: 20px;
  }
}

