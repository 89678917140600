
.icon-favorites:before {
  content: $favoritesIconInactive;
}

.icon-favorites-filled:before {
  content: $favoritesIconActive;
}

.icon-spinner-up:before {
  content: $i-plus;
}

.icon-spinner-down:before {
  content: $i-minus;
}

.icon-account:before {
  content: $i-user;
}

.icon-customer:before {
  content: $i-user;
}

.icon-addresses:before {
  content: $i-addressbook;
}

.icon-history:before {
  content: $i-invoice;
}

.icon-digital-products:before {
  content: $i-download;
}

.icon-digital-tickets:before {
  content: $i-file-document;
}

.icon-newsletter:before {
  content: $i-mail;
}