
.languageSwitchButton {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 3px;
  font-size: 1.6rem;
  > span {
    a {
      text-decoration: none;
      text-transform: uppercase;
      color: $gray-base;
    }
    &.active {
      a {
        color: $black;
        &:hover {
          color: $black;
          cursor:default;
        }
      }
    }
    &:hover {
      a {
        color: $primary;
      }
    }
  }
}

.languageSwitchList {
  padding: 0;
  list-style: none;
  display: flex;
  margin: 0;
  flex-wrap: wrap;

  li {
    width: 100%;
    padding: 0;
    display: flex;
    text-align: center;
    &:not(:last-child){
      border-bottom: 1px solid $middle-gray;
    }

    &.is-active {
      a {
        font-weight: $bold;
      }
    }

    img {
      width: 40px;
      flex-shrink: 0;
    }

    a {
      display: flex;
      align-items: center;
      gap: 20px;
      text-decoration: none;
      color: $text-base;
      padding: 15px 0;
      width: 100%;

      &:hover {
        color: $primary;
      }
    }
  }
}