.tileGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  &--element {
    position: relative;
    min-height: 180px;
    display: flex;
    overflow: hidden;
    > div {
      padding: calc($spacer-base/2);
    }
    h1,.h1,h2,.h2,h3,.h3,h4.h4 {
      margin-bottom: 0;
    }
    .textcontent, .link {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      z-index: 1;
      color: $white;
      font-size: 1.5rem;
      overflow: hidden;
      > * {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .link {
      text-decoration: none;

      img, video {
        transition: scale 200ms ease-in;
      }
      &:hover {
        .button--icon {
          background: $primary;
          &:after {
            color: $white;
          }
          &:before {
            scale: $buttonHoverScale;
          }
        }
        .tile--image {
          img, video {
            scale: 1.1;
          }
        }
      }
    }

  }

  &_3_2 {
    grid-template-columns: repeat(2,1fr);
    .tile--image {
      z-index: 0;
      padding: 0;
      img {
        width: 100%!important;
        object-fit: cover;
      }
      &:after {
        display: none;
      }
    }
  }

  &--portal {
    grid-template-columns: repeat(2, 1fr);
    @media(max-width: $screen-lg-max) {
      > div {
        &:nth-child(2) {
          aspect-ratio: auto;
        }
        &:nth-child(4) {
          aspect-ratio: auto;
        }
      }
    }
    @media(max-width: $screen-xs-max) {
      > div {
        &:first-child {
          order: 1;
        }
        &:nth-child(2) {
          order: 2;
        }
        &:nth-child(3) {
          order: 4;
          grid-column: span 2;
          grid-row: span 1;
        }
        &:nth-child(4) {
          order: 3;
        }
      }
    }


  }
}

.tile--span-1 {
  grid-column: span 1;
  order: 2;
  @media(min-width: $screen-sm) {
    order: unset;
  }
  @media(min-width: $screen-md) {
    aspect-ratio: 5/4;
  }
}

.tile--span-2 {
  grid-column: span 2;
}

.tile--span-4 {
  grid-column: span 4;
}

.tile--span-2-row {
  grid-row: span 2;
}

.tile--image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  display: flex;
  &:not(.tile--image-noOverlay) {
    &:after {
      content:"";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      background: transparent linear-gradient(180deg, #FFFFFF00 0%, rgba(0, 0, 0, 0.4) 100%) 0% 0% no-repeat padding-box;
    ;
    }
  }
  img, video {
    object-fit: cover;
    height: 100%!important;
    position: relative;

  }
  .button--icon {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.tile-span-row-2 {
  grid-row: span 2;
}

@media(min-width: $screen-sm) {
  .tileGrid {
    grid-template-columns: repeat(4, 1fr);
    &--element {
      > div {
        padding: $spacer-base;
      }
    }

    &_3_2 {
      grid-template-columns: repeat(3,1fr);
    }

    &--portal {
      grid-template-columns: repeat(6,1fr);
    }
  }
}

@media(min-width: $screen-xlg) {
  .tile--image {
    .button--icon {
      top: 25px;
      right: 25px;
    }
  }
}


