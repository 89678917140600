/*
Searchbar

You can add minified Classes for each Viewport Size (--minified-xs,--minified-sm,--minified-md,--minified-lg,--minified-xlg)

Markup:
<div class="inputGroup searchBar {{modifier_class}}">
  <input type="text" placeholder="Search..." />
  <span class="inputGroup__addon"><i class="icon icon-search"></i></span>
</div>

.searchBar--minified
.is-focus

Styleguide Components.Searchbar
*/

$itemBoxListGap: $grid-gap;
$itemBoxListGapBetweenRows: $grid-gap;

$itemBoxBreakpointsWidth: (
        xs: 130px,
        sm: 236px,
        md: 220px,
        lg: 280px,
        xlg: 320px,
);

@mixin searchbar-minified() {
  position: relative;
  input {
    width: 0;
    right: 0;
    position: absolute;
    opacity: 0;
    pointer-events: none;
    transition: 0.2s ease-in-out;
  }

  .inputGroup__addon {
    z-index: 1;
    border-radius: $inputBorderRadius;
    padding-left: 5px;
    padding-right: 5px;
    background: transparent;
    border-color: transparent;
  }

  &.is-focus {
    box-shadow: none;

    input {
      width: 300px;
      padding-right: $inputGroupAddonWidth;
      opacity: 1;
      pointer-events: auto;
      border: 1px solid $inputBorderColorFocus;
      border-radius: $inputBorderRadius;
    }

    .inputGroup__addon {
      background: transparent;
      border-color: $searchBarBorderColor;
    }
  }
}

.searchBar {
  width: 100%;
  border-radius: $inputBorderRadius;

  input {
    background: $searchBarBackground;
    border-color: $searchBarBorderColor;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px $searchBarBackground inset !important;
  }

  .inputGroup__addon,
  button.inputGroup__addon {
    transition: 0.2s ease-in-out;
    &:hover,&:focus {
      background: transparent;
      color: $primary;
    }
  }

  &--minified {
    position: relative;

    input {
      width: 0;
      right: 0;
      position: absolute;
      opacity: 0;
      pointer-events: none;
      transition: 0.2s ease-in-out;
    }

    .inputGroup__addon {
      z-index: 1;
    }

    &.is-active {
      input {
        width: 300px;
        padding-right: $inputGroupAddonWidth;
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  &.is-focus {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

    input, .inputGroup__addon,button.inputGroup__addon {
      border-color: $searchBarBorderColor;
      border-left: 0;
      &:hover,&:focus {
        background: $white;
      }
    }
  }

  @media (max-width: $screen-xs-max) {
    &--minified-xs {
      @include searchbar-minified();
    }
  }

  @media(min-width: $screen-sm) and (max-width: $screen-sm-max) {
    &--minified-sm {
      @include searchbar-minified();
    }
  }

  @media(min-width: $screen-md) and (max-width: $screen-md-max) {
    &--minified-md {
      @include searchbar-minified();
    }
  }

  @media(min-width: $screen-lg) and (max-width: $screen-lg-max) {
    &--minified-lg {
      @include searchbar-minified();
    }
  }

  @media(min-width: $screen-xlg) {
    &--minified-xlg {
      @include searchbar-minified();
    }
  }
}

.searchSuggestionItem{
  border-bottom: 1px solid $inputBorderColor;
  text-decoration: none;
  padding:10px;
  color: $text-base;
  display: grid;
  grid-gap: 10px;
  align-items: center;
  grid-template-columns: auto 1fr;
  grid-template-areas: "searchSuggestionImg searchSuggestionDescription";
  cursor: pointer;

  &__img {
    grid-area: searchSuggestionImg;
    width: 60px;
  }

  &__description {
    grid-area: searchSuggestionDescription;
  }

  &:hover,
  &.is-active{
    color: $text-base;
    text-decoration: none;
    background: $light-gray;
  }
}

.searchBarSuggestions {
  background: $white;
  border-radius: $inputBorderRadius;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  max-height: 300px;
  overflow: hidden;
  overflow-y: auto;
}

.searchBarWrapper {
  position: relative;

  .searchBarSuggestions {
    position: absolute;
    left:0;
    top:100%;
    width: 100%;
    z-index: 1;
  }

  &.is-loading {
    .searchBar {
      pointer-events: none;
    }
    .inputGroup__addon {
      .icon {
        animation: rotation 1s infinite ease-in-out;
        &:before {
          content: $i-loading;
        }
      }
    }
  }
}

.headerAccount__mobile {
  .mobileSearch {
    &.js-showSearchBar {
      display: flex;
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      animation: mobileSearchBarOpacity .2s ease-in-out;

      form {
        width: 100%;
        background: $white;
      }

      .searchBar {
        height: 100%;
        border: none;

        input {
          font-size: 1.4rem;
        }

        button {
          padding-left: 10px;
          color: $primary;
        }

        > .icon {
          display: none;
        }
      }

      @keyframes mobileSearchBarOpacity {
        0% {
          opacity: 0;
        }

        100% {
          opacity: 1;
        }
      }
    }
  }
}

.autocomplete {
  &__wrapper {
    position: relative;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: $screen-sm) {
      width: 615px;
    }
  }

  &__search, &__extra_search {
    display: flex;
    align-items: center;

    .aa-Autocomplete {
      flex: 1;
      padding-right: 3px;
    }

    .aa-Form {
      display: flex;
      align-items: center;
      background: $white;
    }

    .aa-InputWrapperSuffix {
      width: 60px;
      background: $primary;
      height: 100%;
    }

    .aa-SubmitButton {
      border: none;
      padding: 0;
      margin-right: 12px;
      background: $white;
      padding-left: 20px;

      svg {
        width: 20px;
        height: 20px;
        fill: $black;
        transition: .2s ease-in-out fill;
        stroke: white;
        stroke-width: 0.75px;
      }

      &:hover {
        svg {
          fill: $primary;
        }
      }
    }

    .aa-ClearButton {
      padding-inline: 20px;
      border: none;
      background: $primary-darken;

      svg {
        fill: $white;
        width: 20px;
        height: 22px!important;
      }
    }

    .aa-InputWrapper {
      flex: 1;
      display: flex;

      input {
        border: none;
        min-width: 180px;
        padding-left: 5px;
        line-height: 1;
      }
      input::placeholder {
        color: $light-gray;
        opacity: 1;
      }

      input::-ms-input-placeholder {
        color: $light-gray;
      }

      @media(min-width: 500px) {
        input {
          min-width: 400px;
        }
      }
    }

    > .icon {
      order: 1;
      color: $dark-gray;
      font-size: 1.6rem;

      &:hover {
        color: $primary;
        cursor: pointer;
      }

      @media(max-width: $screen-md-max) {
        display: none;
      }
    }
  }

  &__results {
    position: absolute;
    top: 100%;
    width: 100%;
    background: #e1e1e1;
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.2);
    overflow: auto;

    .aa-Panel--scrollable {
      max-height: 80vh;
      overflow: auto;
    }

    ul {
      padding: 40px;
      margin: 0;
      list-style: none;
      max-width: $container;
      margin-inline: auto;
    }

    li {
      margin: 0;
      border: 1.5px transparent solid;

    }
  }

  &__item {
    text-decoration: none;
    padding: 6px 4px 4px;
    display: flex;
    border-bottom: 1px solid $middle-gray;
    flex-direction: column;

    > div {
      display: flex;
      flex-direction: column;
    }

    &:hover {
      text-decoration: none;
    }
  }

  &__name {
    font-weight: $bold;
    width: 100%;
    display: flex;

  }

  &__itemNumber {
    white-space: nowrap;
  }
}

.headerAccount__mobile {
  overflow: visible;

  .mobileSearch {
    .autocomplete {
      &__wrapper {
        width: 100%;
      }

      &__search {
        width: 100%;
        height: 100%;
        border: none;
        background: $white;
        border-radius: 30px;
        padding: 0 12px;
        position: relative;
        z-index: 1;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
      }

      &__results {
        top: 50%;

        ul {
          padding-top: 36px;
        }
      }
    }

    &.js-showSearchBar {
      display: flex;
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      animation: mobileSearchBarOpacity .2s ease-in-out;

      @keyframes mobileSearchBarOpacity {
        0% {
          opacity: 0;
        }

        100% {
          opacity: 1;
        }
      }
    }
  }
}


.autocomplete {
  &__wrapper {
    .aa-Autocomplete {
      //background-color: green;
    }
  }
}
#searchBoxSkeleton {
  display: none;
  @media screen and (min-width: #{$screen-tablet}) {
    display: flex;
    align-items: center;
    margin-right: auto;
  }
}

.aa-notVisible {
  //display: none;
}

.algoliaSearchWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $light-gray;
  z-index: 5;
  transition: height 500ms ease-in, z-index 100ms ease-in 100ms;

  &.aa-notVisible {
    height: 0;
    opacity: 0;
    z-index: -100;
    background: $white;
  }

  &.algoliaExtraSearchWrapper {
    position: relative;
    background: $white;
    height: fit-content;
    max-width: 450px;
    z-index: 4;

    .autocomplete__extra_wrapper {
      position: relative;
      flex-grow: 1;
      .autocomplete__extra_results {
        position: absolute;
        left: 0;
        max-height: 400px;
        overflow: auto;
      }
    }

    .aa-Autocomplete {
      padding-right: 0;
    }

    .aa-List {
      grid-template-columns: 1fr;
      padding: 20px;
      background: $light-gray;

      .itembox__main {
        display: grid;
        grid-template-columns: 1fr 4fr;
        align-items: center;
        border: 1px solid transparent;

        .itembox__hoverImg--wrapper, .itembox__actions {
          display: none;
        }
        .itembox__head {
          padding: 10px;
        }
        .itembox__image {
          img {
            padding: 0;
          }
        }

        &:hover {
          border-color: $primary;
        }
      }
    }

    .aa-InputWrapper {
      input {
        min-width: 300px;
        padding-right: 10px;
        @media(max-width: $screen-sm-max) {
          min-width:180px;
        }
      }
    }
  }

  .aa-Form {
    border: 1px solid transparent;
  }
  &.is-focus, &.is-filled {
    .aa-Form {
      border: 1px solid $primary;
    }

  }
}

.aa-list {
  display: grid;
  grid-column-gap: $itemBoxListGap;
  grid-row-gap: $itemBoxListGapBetweenRows;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  @each $breakpoint, $width in $itemBoxBreakpointsWidth {
    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
      grid-template-columns: repeat(auto-fill, minmax($width,1fr));
    }
  }
}

.aa-item {
  display: flex;
  > div {
    flex-grow: 1;
    display: flex;
    align-items: stretch;
    > div {
      display: flex;
      flex-grow: 1;
    }
  }
}

.ais-SearchBox {
  &-form {
    position: relative;
    display: flex;
    margin-bottom: 20px;
  }

  &-input {
    padding-left: 40px;
    position: relative;
    background: url(/layout/frontend/default/global/icons/search.svg);
    background-repeat: no-repeat;
    background-size: 22px;
    background-position: center left;
    border-color: $middle-gray;
    transition: border-color .2s ease-in-out;

    &::placeholder {
      color: $dark-gray;
    }

    &:hover, &:focus {
      border-color: $primary;
    }

    &:disabled {
      display: none;
    }
  }

  &-submit {
    display: none;
  }

  &-reset {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    right: 0;
    padding: 5px;
    background: $primary-darken;
    border: none;

    svg {
      fill: $white;
    }

    &:hover {
      background: $black;
    }
  }
}

