.uspElement {
  font-size: 1.4rem;
  padding: 1rem;
  position: relative;
  > .textcontent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    .icon {
      font-size: 3.5rem;
    }
    span {
      max-width: 235px;
      margin-inline: auto;
    }
  }
  &:nth-child(1) {
    &:after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      height: calc(100% - 15px);
      width: 1px;
      background: $black;
    }
  }
  &:nth-child(2) {
    &:after {
      content: "";
      position: absolute;
      bottom: -1px;
      right: 0;
      height: 1px;
      width: calc(100% - 15px);
      background: $black;
    }
    &:last-child {
      &:after {
        content: none;
      }
    }
  }
  &:nth-child(3) {
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 1px;
      width: calc(100% - 15px);
      background: $black;
    }
  }
  &:nth-child(4) {
    &:after {
      content: "";
      position: absolute;
      left: -1px;
      bottom: 0;
      height: calc(100% - 15px);
      width: 1px;
      background: $black;
    }
  }

  @media(min-width: $screen-sm) {
    font-size: $font-size-base;
    > .textcontent {

      .icon {
        font-size: 4.5rem;
      }
    }
  }
  @media(min-width: $screen-md) {
    padding: $spacer-base*1.5;
    &:not(:last-child) {
    border-right: 1px solid $black;
  }
    &:after {
      content: none!important;
    }
  }
}

@media(max-width: $screen-sm-max) {
  div[class*="scrollSnap"] {
    .uspElement {
      &:after {
        content: none;
      }
    }
  }
}
