/*
Grid

Extra Small (xs), Small (sm), Medium (md), Large (lg), xLarge (xlg)

Markup:
<div class="row {{modifier_class}}">
  <div class="col marginBottom"><div class="kssPaddingBox">col</div></div>
</div>
<div class="row {{modifier_class}}">
  <div class="col-12 marginBottom"><div class="kssPaddingBox">col-12</div></div>
</div>
<div class="row {{modifier_class}}">
  <div class="col-1 marginBottom"><div class="kssPaddingBox">col-1</div></div>
  <div class="col-11 marginBottom"><div class="kssPaddingBox">col-11</div></div>
</div>
<div class="row {{modifier_class}}">
  <div class="col-2 marginBottom"><div class="kssPaddingBox">col-2</div></div>
  <div class="col-10 marginBottom"><div class="kssPaddingBox">col-10</div></div>
</div>
<div class="row {{modifier_class}}">
  <div class="col-3 marginBottom"><div class="kssPaddingBox">col-3</div></div>
  <div class="col-9 marginBottom"><div class="kssPaddingBox">col-9</div></div>
</div>
<div class="row {{modifier_class}}">
  <div class="col-4 marginBottom"><div class="kssPaddingBox">col-4</div></div>
  <div class="col-8 marginBottom"><div class="kssPaddingBox">col-8</div></div>
</div>
<div class="row {{modifier_class}}">
  <div class="col-5 marginBottom"><div class="kssPaddingBox">col-5</div></div>
  <div class="col-7 marginBottom"><div class="kssPaddingBox">col-7</div></div>
</div>
<div class="row {{modifier_class}}">
  <div class="col-6 marginBottom"><div class="kssPaddingBox">col-6</div></div>
  <div class="col-6 marginBottom"><div class="kssPaddingBox">col-6</div></div>
</div>

.gridGap--noGap - No Gap
.gridGap--small - Small Gap
.gridGap--large - Large Gap
.gridGap--xlarge - Extra large Gap


Styleguide Structure.Grid
*/

*, ::after, ::before {
  box-sizing: border-box;
}

.row {
  min-width: 100%;
  @include make-row();

  > * {
    @include make-col-ready();
    //container: containerRowColumn / inline-size;

    &.marginBottom {
      margin-bottom: $grid-gap;
    }
  }
}

@include make-grid-columns();

@media (min-width: $screen-md) {
  .col-md-1-5 {
    width: 20%;
  }
  .col-md-2-5 {
    width: 40%;
  }
  .col-md-3-5 {
    width: 60%;
  }
  .col-md-4-5 {
    width: 80%;
  }
  .col-md-5-5 {
    width: 100%;
  }
}

@media (min-width: $screen-lg) {
  .col-lg-1-5 {
    width: 20%;
  }
  .col-lg-2-5 {
    width: 40%;
  }
  .col-lg-3-5 {
    width: 60%;
  }
  .col-lg-4-5 {
    width: 80%;
  }
  .col-lg-5-5 {
    width: 100%;
  }
}

@media (min-width: $screen-xlg) {
  .col-xlg-1-5 {
    width: 20%;
  }
  .col-xlg-2-5 {
    width: 40%;
  }
  .col-xlg-3-5 {
    width: 60%;
  }
  .col-xlg-4-5 {
    width: 80%;
  }
  .col-xlg-5-5 {
    width: 100%;
  }
}

.row.gridGap--noGap {
  margin-left: 0;
  margin-right: 0;

  > * {
    padding-left: 0;
    padding-right: 0;

    &.marginBottom {
      margin-bottom: 0;
    }
  }
}

.row.gridGap--small {
  margin-left: calc(-1 * $grid-gap-small / 2);
  margin-right: calc(-1 * $grid-gap-small / 2);

  > * {
    padding-left: calc($grid-gap-small / 2);
    padding-right: calc($grid-gap-small / 2);

    &.marginBottom {
      margin-bottom: $grid-gap-small;
    }
  }
}

.row.gridGap--large {
  margin-left: calc(-1 * $grid-gap-large / 4);
  margin-right: calc(-1 * $grid-gap-large / 4);
  > * {
    padding-left: calc($grid-gap-large / 4);
    padding-right: calc($grid-gap-large / 4);

    &.marginBottom {
      margin-bottom: $grid-gap-large;
    }
  }
  @media (min-width: $container-large + $grid-gap-large) {
    margin-left: calc(-1 * $grid-gap-large / 2);
    margin-right: calc(-1 * $grid-gap-large / 2);
    > * {
      padding-left: calc($grid-gap-large / 2);
      padding-right: calc($grid-gap-large / 2);

      &.marginBottom {
        margin-bottom: $grid-gap-large;
      }
    }
  }
}

.row.gridGap--xlarge {
  margin-left: calc(-1 * $grid-gap-large / 2);
  margin-right: calc(-1 * $grid-gap-large / 2);
  > * {
    padding-left: calc($grid-gap-large / 2);
    padding-right: calc($grid-gap-large / 2);

    &.marginBottom {
      margin-bottom: $grid-gap-large;
    }
  }
  @media (min-width: $container-large + $grid-gap-large) {
    margin-left: calc(-1 * $grid-gap-xlarge / 2);
    margin-right: calc(-1 * $grid-gap-xlarge / 2);
    > * {
      padding-left: calc($grid-gap-xlarge / 2);
      padding-right: calc($grid-gap-xlarge / 2);

      &.marginBottom {
        margin-bottom: $grid-gap-xlarge;
      }
    }
  }
}

.container--small {
  > .row.gridGap--large {
    @media (min-width: $container-small + $grid-gap-large) {
      margin-left: calc(-1 * $grid-gap-large / 2);
      margin-right: calc(-1 * $grid-gap-large / 2);
      > * {
        padding-left: calc($grid-gap-large / 2);
        padding-right: calc($grid-gap-large / 2);

        &.marginBottom {
          margin-bottom: $grid-gap-large;
        }
      }
    }
  }
}

.container--xsmall {
  > .row.gridGap--large {
    @media (min-width: $container-xsmall + $grid-gap-large) {
      margin-left: calc(-1 * $grid-gap-large / 2);
      margin-right: calc(-1 * $grid-gap-large / 2);
      > * {
        padding-left: calc($grid-gap-large / 2);
        padding-right: calc($grid-gap-large / 2);

        &.marginBottom {
          margin-bottom: $grid-gap-large;
        }
      }
    }
  }
}

.row.scrollSnapTablet {
  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    flex-wrap: nowrap;
    margin-left: - calc($grid-gap / 2);
    margin-right: - calc($grid-gap / 2);
    padding-left: calc($grid-gap / 2 - 8px);
    > * {
      scroll-snap-align: center;
      flex-shrink: 0;
      padding-left: 8px;
      padding-right: 8px;
      @include calc(width, "100% - #{$grid-gap} / 2");

      &.col-6 {
        @include calc(width, "50% - #{$grid-gap} / 2");
      }

      &.col-4 {
        @include calc(width, "33% - #{$grid-gap} / 2");
      }

      &.col-8 {
        @include calc(width, "67% - #{$grid-gap} / 2");
      }
    }

    &.justify-content-center {
      justify-content: flex-start;
    }
  }
}

.row.scrollSnapMobile {
  @media (max-width: $screen-xs-max) {
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    flex-wrap: nowrap;
    margin-left: - calc($grid-gap / 2);
    margin-right: - calc($grid-gap / 2);
    padding-left: calc($grid-gap / 2 - 8px);
    > * {
      scroll-snap-align: center;
      flex-shrink: 0;
      padding-left: 8px;
      padding-right: 8px;
      @include calc(width, "100% - #{$grid-gap} / 2");

      &.col-6 {
        @include calc(width, "50% - #{$grid-gap} / 2");
      }

      &.col-4 {
        @include calc(width, "33% - #{$grid-gap} / 2");
      }

      &.col-8 {
        @include calc(width, "67% - #{$grid-gap} / 2");
      }
    }

    &.justify-content-center {
      justify-content: flex-start;
    }
  }
}

.order-sm-1 {
  @media (min-width: $screen-sm) {
    order: 1;
  }
}

.order-md-1 {
  @media (min-width: $screen-md) {
    order: 1;
  }
}

.order-lg-1 {
  @media (min-width: $screen-lg) {
    order: 1;
  }
}

$align-items: (
        start: flex-start,
        end: flex-end,
        center: center,
        baseline: baseline,
        stretch: stretch
) !default;

$align-self: (
        start: flex-start,
        end: flex-end,
        center: center,
        baseline: baseline,
        stretch: stretch
) !default;

$justify-content: (
        start: flex-start,
        end: flex-end,
        center: center,
        between: space-between,
        around: around
) !default;

@each $key, $value in $align-items {
  .align-items-#{$key} {
    align-items: $value;
  }
}

@each $key, $value in $align-self {
  .align-self-#{$key} {
    align-self: $value;
  }
}

@each $key, $value in $justify-content {
  .justify-content-#{$key} {
    justify-content: $value;
  }
}

@each $breakpoint, $size in $grid-breakpoints {
  @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
    @each $key, $value in $align-items {
      .align-items-#{$breakpoint}-#{$key} {
        align-items: $value;
      }
    }
    @each $key, $value in $align-self {
      .align-self-#{$breakpoint}-#{$key} {
        align-self: $value;
      }
    }
    @each $key, $value in $justify-content {
      .justify-content-#{$breakpoint}-#{$key} {
        justify-content: $value;
      }
    }
  }
}

.row--flexsub {
  > * {
    display: flex;
  }
}

.fullwidth {
  width: 100%;
}

#container {
  background-color: $body-background;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .header,
  .footer {
    flex-shrink: 0;
  }

  .mainContent {
    flex-grow: 1;
  }
}

.textbox--550 {
  @media(min-width: $screen-lg) {
    max-width: 550px;
    margin-inline: auto;
  }
}

body {
  &.pg_shop {
    &:not(.card, .productFinderResult, .search, .favorites, .shoppingworld,.productcomparison) {
      .mainContent {
        display: flex;
        flex-direction: column;
        .breadcrumbContainer {
          order: 2
        }
        .categoryInfo {
          order: 1;
        }
        .shopContainer {
          order: 3;
        }
      }
    }
  }
}

