/*
Embeds

Markup:
<div class="embedResponsive embedResponsive--1by1"><iframe src="https://youtu.be/llky_ddeL0I"></iframe></div><br/>
<div class="embedResponsive embedResponsive--4by3"><iframe src="https://youtu.be/llky_ddeL0I"></iframe></div><br/>
<div class="embedResponsive embedResponsive--16by9"><iframe src="https://youtu.be/llky_ddeL0I"></iframe></div><br/>
<div class="embedResponsive embedResponsive--21by9"><iframe src="https://youtu.be/llky_ddeL0I"></iframe></div>

//Styleguide Base.Embeds
*/

iframe,
embed,
object {
  max-width: 100%;
  border: 0;
}

.embedResponsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
  width: 100%;

  > * {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100% !important;
    width: 100% !important;
    border: 0;
  }
}

// Modifier class for 1:1 aspect ratio
.embedResponsive--1by1 {
  padding-bottom: 100%;
}

// Modifier class for 4:3 aspect ratio
.embedResponsive--4by3 {
  padding-bottom: 75%;
}

// Modifier class for 16:9 aspect ratio
.embedResponsive--16by9 {
  padding-bottom: 56.25%;
}

// Modifier class for 21:9 aspect ratio
.embedResponsive--21by9 {
  padding-bottom: 42.85%;
}