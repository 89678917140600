.tabContent {
  &:not(.active) {
    display: none;
  }
}

.tabWrapper {
  display: flex;
  gap: $spacer-base*2;
}

.tab-button {
  &:last-child {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: -$spacer-base;
      height: 100%;
      width: 1px;
      background: $black;
    }
  }
  &.active {
    &:after {
      width: 100%;
    }
  }
}