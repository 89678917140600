/*
Headlines

Markup:
<h1>h1</h1>
<h2>h2</h2>
<h3>h3</h3>
<h4>h4</h4>
<h5>h5</h5>
<h6>h6</h6>
<div class="h1">.h1</div>
<div class="h2">.h2</div>
<div class="h3">.h3</div>
<div class="h4">.h4</div>
<div class="h5">.h5</div>
<div class="h6">.h6</div>
<h1 class="highlighted">h1.highlighted</h1>
<h2 class="highlighted">h2.highlighted</h2>
<h3 class="highlighted">h3.highlighted</h3>
<h4 class="highlighted">h4.highlighted</h4>
<h5 class="highlighted">h5.highlighted</h5>
<h6 class="highlighted">h6.highlighted</h6>

Styleguide Typo.Headlines
*/

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-weight: $headings-font-weight;
  @if ($font-family != $headings-font-family) {
    font-family: $headings-font-family;
  }
  @if ($line-height-base != $headings-line-height) {
    line-height: $headings-line-height;
  }
  @if ($text-base != $headings-color) {
    color: $headings-color;
  }
  margin: 0;
  margin-bottom: clamp(10px, 1em, 20px);

  &.highlighted {
    font-weight: $headings-highlighted-font-weight;
    line-height: $headings-highlighted-line-height;
  }
}

h1, .h1 {
  margin-bottom: clamp(10px, 1em, 30px);
  @if ($headings-font-weight != $h1-font-weight) {
    font-weight: $h1-font-weight;
  }
  @if (length($h1-font-size-breakpoints) > 0) {
    @each $breakpoint, $value in $h1-font-size-breakpoints {
      @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
        font-size: $value;
      }
    }
  } @else {
    font-size: $h1-font-size;
  }

  @if (length($highlighted-h1-breakpoints) > 0) {
    &.highlighted {
      @each $breakpoint, $value in $highlighted-h1-breakpoints {
        @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
          font-size: $value;
        }
      }
    }
  }
}

h2, .h2 {
  @if ($headings-font-weight != $h2-font-weight) {
    font-weight: $h2-font-weight;
  }
  @if (length($h2-font-size-breakpoints) > 0) {
    @each $breakpoint, $value in $h2-font-size-breakpoints {
      @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
        font-size: $value;
      }
    }
  } @else {
    font-size: $h2-font-size;
  }

  @if (length($highlighted-h2-breakpoints) > 0) {
    &.highlighted {
      @each $breakpoint, $value in $highlighted-h2-breakpoints {
        @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
          font-size: $value;
        }
      }
    }
  }
}

h3, .h3 {
  @if ($headings-font-weight != $h3-font-weight) {
    font-weight: $h3-font-weight;
  }
  @if (length($h3-font-size-breakpoints) > 0) {
    @each $breakpoint, $value in $h3-font-size-breakpoints {
      @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
        font-size: $value;
      }
    }
  } @else {
    font-size: $h3-font-size;
  }

  @if (length($highlighted-h3-breakpoints) > 0) {
    &.highlighted {
      @each $breakpoint, $value in $highlighted-h3-breakpoints {
        @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
          font-size: $value;
        }
      }
    }
  }
}

h4, .h4 {
  @if ($headings-font-weight != $h4-font-weight) {
    font-weight: $h4-font-weight;
  }
  @if (length($h4-font-size-breakpoints) > 0) {
    @each $breakpoint, $value in $h4-font-size-breakpoints {
      @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
        font-size: $value;
      }
    }
  } @else {
    font-size: $h4-font-size;
  }
}

h5, .h5 {
  @if ($headings-font-weight != $h5-font-weight) {
    font-weight: $h5-font-weight;
  }
  @if (length($h5-font-size-breakpoints) > 0) {
    @each $breakpoint, $value in $h5-font-size-breakpoints {
      @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
        font-size: $value;
      }
    }
  } @else {
    font-size: $h5-font-size;
  }
}

h6, .h6 {
  @if ($headings-font-weight != $h6-font-weight) {
    font-weight: $h6-font-weight;
  }
  @if (length($h6-font-size-breakpoints) > 0) {
    @each $breakpoint, $value in $h6-font-size-breakpoints {
      @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
        font-size: $value;
      }
    }
  } @else {
    font-size: $h6-font-size;
  }
}