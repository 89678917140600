/*
Iconbar

Markup:
<div class="iconbar {{modifier_class}}">
    <div class="iconbarItem">
      <a class="iconbarIcon" href="#" title="Account">
          <span class="iconbarIcon__icon">
              <i class="icon icon-user" aria-hidden="true"></i>
          </span>
          <span class="iconbarIcon__label">Account</span>
      </a>
      <div class="iconbarHover iconbarHover--basket">
          <div class="iconbarHover__close">
              <i class="icon icon-close"></i>
          </div>
          <div class="iconbarHover__headline">
              Headline
          </div>
          <div class="iconbarHover__body">
            <ul class="iconbarNavigation">
              <li><a href="#">Link</a></li>
              <li><a href="#">Link</a></li>
            </ul>
          </div>
          <div class="iconbarHover__footer">
              <div class="amountLines">
                  <div class="amountLine amountLine--big">
                      <div class="amountLine__label">Sum</div>
                      <div class="amountLine__value">999,99&nbsp;€</div>
                  </div>
              </div>
              <a href="#" class="button button--action button--xl" style="width: 100%">
                  To Basket
              </a>
          </div>
      </div>
    </div>
    <div class="iconbarItem">
      <a class="iconbarIcon" href="#" title="Favorites">
          <span class="iconbarIcon__icon">
              <i class="icon icon-favorites" aria-hidden="true"></i>
              <span class="iconbarIcon__count">+99</span>
          </span>
          <span class="iconbarIcon__label">Favorites</span>
      </a>
    </div>
    <div class="iconbarItem">
      <a class="iconbarIcon active" href="#" title="Basket">
          <span class="iconbarIcon__icon">
              <i class="icon icon-basket" aria-hidden="true"></i>
              <span class="iconbarIcon__count">+99</span>
          </span>
          <span class="iconbarIcon__label">Basket</span>
      </a>
    </div>
    <div class="iconbarItem">
      <a class="iconbarIcon active" href="#" title="Name">
          <span class="iconbarIcon__label">Max Mustermann</span>
          <span class="iconbarIcon__badge">MM</span>
      </a>
    </div>
</div>

.iconbar--landscape - Orientation landscape

Styleguide Components.Iconbar
*/

.iconbarIcon {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: $iconbarIconColor;
  text-decoration: none;
  position: relative;

  &:hover, &:focus, &:active, &.is-active {
    text-decoration: none;
    color: $iconbarIconColor;

    .iconbarIcon__icon {
      color: $iconbarIconColorHover;
    }
  }

  &__icon {
    font-size: $iconbarIconFontSize;
    display: flex;
    align-items: center;
    justify-content: center;
    width: $iconbarIconWidth;
    height: $iconbarIconHeight;
    flex-shrink: 0;
  }

  &__badge {
    width: $iconbarBadgeWidth;
    height: $iconbarBadgeHeight;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-shrink: 0;
    margin-left: 10px;
    background: $iconbarBadgeBackgroundColor;
    color: $iconbarBadgeColor;
  }

  &__label {
    font-size: $iconbarIconLabelFontSize;
    line-height: 1;
    display: none;

    @media (min-width: $showIconLabelBreakpoint) {
      display: block;
    }
  }

  &__count {
    position: absolute;
    right: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    background: $iconbarIconCountBackground;
    color: $iconbarIconCountColor;

    @media (min-width: $showIconLabelBreakpoint) {
      right: calc($iconbarSpaceBetweenIcons / 2);
    }

    &.is-action {
      display: flex !important;
      animation: scaleRequestIconBigger 0.5s forwards ease-in-out;
    }
  }
}

.iconbar {
  display: flex;
  align-items: flex-end;

  @media (min-width: $showIconLabelBreakpoint) {
    margin-left: calc(-1 * $iconbarSpaceBetweenIcons / 2);
    margin-right: calc(-1 * $iconbarSpaceBetweenIcons / 2);

    .iconbarIcon {
      padding-left: calc($iconbarSpaceBetweenIcons / 2);
      padding-right: calc($iconbarSpaceBetweenIcons / 2);
    }
  }

  &--landscape {
    .iconbarIcon {
      flex-direction: row;

      &__count {
        @media (min-width: $showIconLabelBreakpoint) {
          right: auto;
          left: $iconbarIconWidth;
          margin-left: -10px;
        }
      }
    }
  }

  &:not(.iconbar--landscape){
    .iconbarIcon__badge {
      order: -1;
      margin:0;
      margin-bottom: 5px;
    }
  }
}

.iconbarHover {
  z-index: $zindexIconbarHover;
  background: $white;
  padding: 20px 30px;
  display: none;
  flex-direction: column;
  width: 250px;
  max-width: 100vw;
  position: absolute;
  top: 100%;
  bottom: auto;
  right: 50%;
  max-height: 70vh;
  border-radius: $inputBorderRadius;
  transform: translateX(50px);
  margin-top: 20px;
  filter: drop-shadow(0 5px 20px rgba(0, 0, 0, 0.1));

  &:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-width: 0 20px 20px 20px;
    border-color: transparent transparent $white transparent;
    border-style: solid;
    position: absolute;
    right: 30px;
    bottom: 100%;
  }

  @media (max-width: $screen-xs-max) {
    display: none;
  }

  &--bigger {
    width: 320px;
    transform: translateX(95px);
  }

  &__close {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1;
    @media (min-width: $screen-sm) {
      display: none;
    }
  }

  &__navigation {
    margin: 0;
    padding: 0;
    font-size: 1.5rem;

    li {
      margin: 0;
      padding: 0;
      list-style-type: none;
      border-bottom: 1px solid $inputBorderColor;

      &:last-child {
        border-bottom: 0;
      }

      > span,
      > a {
        display: flex;
        align-items: center;
        color: $text-base;
        text-decoration: none;
        padding-top: 10px;
        padding-bottom: 10px;
        cursor: pointer;

        &:hover, &:focus, &:active, &.is-active {
          color: $primary;
          text-decoration: none;
        }

        .icon {
          font-size: 1.4em;
          margin-right: 10px;
        }
      }
    }
  }

  &__headline {
    font-weight: $bold;
    margin-bottom: 10px;
  }

  &__body {
    overflow: hidden;
    overflow-y: auto;
    //fix for scrollbar
    padding-right: 10px;
    margin-right: -10px;
  }

  &__footer {
    padding-top: 20px;
  }
}

.iconbarItem {
  position: relative;

  &.hoverintent,
  &.showIconbarHover {
    @media (min-width: $screen-sm) {
      .iconbarHover {
        display: flex;
      }
    }
  }
}