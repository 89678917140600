/*
Background

Markup:
<div class="kssColorBoxes">
  <div class="kssColorBox bg--white">
    <h1>Headline 1</h1>
    Text<br/>
    <a href="#">Link</a><br/><br/>
    <a href="#" class="button">Button</a><br/><br/>
    <a href="#" class="button button--primary">Button</a><br/><br/>
    <a href="#" class="button button--action">Button</a>
  </div>
  <div class="kssColorBox bg--black">
    <h1>Headline 1</h1>
    Text<br/>
    <a href="#">Link</a><br/><br/>
    <a href="#" class="button">Button</a><br/><br/>
    <a href="#" class="button button--primary">Button</a><br/><br/>
    <a href="#" class="button button--action">Button</a>
  </div>
  <div class="kssColorBox bg--gray-base">
    <h1>Headline 1</h1>
    Text<br/>
    <a href="#">Link</a><br/><br/>
    <a href="#" class="button">Button</a><br/><br/>
    <a href="#" class="button button--primary">Button</a><br/><br/>
    <a href="#" class="button button--action">Button</a>
  </div>
  <div class="kssColorBox bg--light-gray">
    <h1>Headline 1</h1>
    Text<br/>
    <a href="#">Link</a><br/><br/>
    <a href="#" class="button">Button</a><br/><br/>
    <a href="#" class="button button--primary">Button</a><br/><br/>
    <a href="#" class="button button--action">Button</a>
  </div>
  <div class="kssColorBox bg--primary-red">
    <h1>Headline 1</h1>
    Text<br/>
    <a href="#">Link</a><br/><br/>
    <a href="#" class="button">Button</a><br/><br/>
    <a href="#" class="button button--primary">Button</a><br/><br/>
    <a href="#" class="button button--action">Button</a>
  </div>
  <div class="kssColorBox bg--secondary-rost-pastel">
    <h1>Headline 1</h1>
    Text<br/>
    <a href="#">Link</a><br/><br/>
    <a href="#" class="button">Button</a><br/><br/>
    <a href="#" class="button button--primary">Button</a><br/><br/>
    <a href="#" class="button button--action">Button</a>
  </div>
  <div class="kssColorBox bg--action">
    <h1>Headline 1</h1>
    Text<br/>
    <a href="#">Link</a><br/><br/>
    <a href="#" class="button">Button</a><br/><br/>
    <a href="#" class="button button--primary">Button</a><br/><br/>
    <a href="#" class="button button--action">Button</a>
  </div>
</div>

Styleguide Colors.Background
*/
/*
Border

Markup:
<div class="kssColorBoxes">
  <div class="kssColorBox border--white">
    <h1>Headline 1</h1>
    Text<br/>
    <a href="#">Link</a>
  </div>
  <div class="kssColorBox border--black">
    <h1>Headline 1</h1>
    Text<br/>
    <a href="#">Link</a>
  </div>
  <div class="kssColorBox border--gray-base">
    <h1>Headline 1</h1>
    Text<br/>
    <a href="#">Link</a>
  </div>
  <div class="kssColorBox border--light-gray">
    <h1>Headline 1</h1>
    Text<br/>
    <a href="#">Link</a>
  </div>
  <div class="kssColorBox border--primary">
    <h1>Headline 1</h1>
    Text<br/>
    <a href="#">Link</a>
  </div>
  <div class="kssColorBox border--secondary">
    <h1>Headline 1</h1>
    Text<br/>
    <a href="#">Link</a>
  </div>
  <div class="kssColorBox border--action">
    <h1>Headline 1</h1>
    Text<br/>
    <a href="#">Link</a>
  </div>
</div>

Styleguide Colors.Border
*/
/*
Font

Markup:
<span class="{{modifier_class}}">Text</span>

.color--white - White
.color--gray-base - Gray Base
.color--middle-gray - Middle Gray
.color--dark-gray - Dark Gray
.color--light-gray - Light Gray
.color--primary-red - Primary Rot
.color--primary-rost - Primary Rost
.color--primary-beige - Primary Beige
.color--primary-green - Primary Grün
.color--primary-cyan - Primary Cyan Blau
.color--primary-blue - Primary Blau
.color--secondary-rost-pastel - Secondary Rost Pastell
.color--secondary-beige-pastel - Secondary Beige Pastell
.color--secondary-green-pastel - Secondary Grün Pastell
.color--secondary-blue-pastel - Secondary Blau Pastell
.color--action - Action
.color--danger - Danger
.color--warning - Warning
.color--success - Success

Styleguide Colors.Font
*/

$custom-colors: (
    "white": $main-color-white,
    "black": $main-color-black,
    "gray-base": $main-color-gray-base,
    "middle-gray": $main-color-middle-gray,
    "dark-gray": $main-color-dark-gray,
    "light-gray": $main-color-light-gray,
    "soft-gray": $main-color-soft-gray,
    "primary-red": $main-color-primary-red,
    "primary-rost": $main-color-primary-rost,
    "primary-beige": $main-color-primary-beige,
    "primary-green": $main-color-primary-green,
    "primary-cyan": $main-color-primary-cyan,
    "primary-blue": $main-color-primary-blue,
    "secondary-rost-pastel": $main-color-secondary-rost-pastel,
    "secondary-beige-pastel": $main-color-secondary-beige-pastel,
    "secondary-green-pastel": $main-color-secondary-green-pastel,
    "secondary-blue-pastel": $main-color-secondary-blue-pastel,
    "action": $main-color-action,
    "danger": $main-color-danger,
    "warning": $main-color-warning,
    "success": $main-color-success,
    "info": $main-color-info
);

$custom-colors-contrast: (
    "black": colorTextContrast($main-color-black),
    "gray-base": colorTextContrast($main-color-gray-base),
    "middle-gray": colorTextContrast($main-color-middle-gray),
    "dark-gray": colorTextContrast($main-color-dark-gray),
    "light-gray": colorTextContrast($main-color-light-gray),
    "soft-gray": colorTextContrast($main-color-soft-gray),
    "primary-red": colorTextContrast($main-color-primary-red),
    "primary-rost": colorTextContrast($main-color-primary-rost),
    "primary-beige": colorTextContrast($main-color-primary-beige),
    "primary-green": colorTextContrast($main-color-primary-green),
    "primary-cyan": colorTextContrast($main-color-primary-cyan),
    "primary-blue": colorTextContrast($main-color-primary-blue),
    "secondary-rost-pastel": colorTextContrast($main-color-secondary-rost-pastel),
    "secondary-beige-pastel": colorTextContrast($main-color-secondary-beige-pastel),
    "secondary-green-pastel": colorTextContrast($main-color-secondary-green-pastel),
    "secondary-blue-pastel": colorTextContrast($main-color-secondary-blue-pastel),
    "action": colorTextContrast($main-color-action),
    "danger": colorTextContrast($main-color-danger),
    "warning": colorTextContrast($main-color-warning),
    "success": colorTextContrast($main-color-success),
    "info": colorTextContrast($main-color-info)
);

@each $color, $value in $custom-colors {
  .bg--#{$color} {
    background-color: $value;

    @if (map-has-key($custom-colors-contrast, $color)) {
      color: map-get($custom-colors-contrast, $color);
    }

    a:not(.button), .link {
      @if (map-has-key($custom-colors-contrast, $color)) {
        color: map-get($custom-colors-contrast, $color);
      }
    }

    > .link {
      &:hover {
        background-color: darken($value, 10);
      }
    }
  }

  .border--#{$color} {
    border-color: $value;
  }

  .color--#{$color} {
    color: $value;

    a, .link {
      &:not(:hover):not(:focus):not(:active) {
        color: $value;
      }
    }
  }

  .link {
    &:hover {
      .bg--#{$color} {
        background-color: darken($value, 10);
      }
    }
  }
}

.bg--black {
  .button--action {
    background: $white;
    color: $black;
  }
}

.bg--action {
  .button--action {
    background: $white;
    color: $black;
  }
}

.bg--primary {
  .button:hover {
    background: $main-color-primary-dark;
  }
  .button--primary {
    background: $white;
    color: $black;
  }
}

.bg--primary-blue, .bg--primary-green, .bg--primary-beige, .bg--secondary-blue-pastel, .bg--primary-rost {
  color: $white;
  a:not(.button), .link {
    color: $white;
  }
  .button--linkWithIcon {
    color: $white;
  }
  .button:hover {
    background: $main-color-primary-dark;
  }
  .button--primary {
    background: $white;
    color: $black;
  }
  > .link {
    &:hover {
      color: $white;
    }
  }
}

.bg--primary-blue {
  background-color: darken(#819AB9, 10);
}