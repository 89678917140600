
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

::-webkit-scrollbar-thumb {
  background: $backgroundColorScrollbar;
  border: 0 none transparent;
  border-radius: 0;
}

::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:active {
  background: $backgroundColorScrollbarActive;
}

::-webkit-scrollbar-track {
  background: transparent;
  border: 0 none transparent;
  border-radius: 0;
}

::-webkit-scrollbar-track:hover {
  background: transparent;
}

::-webkit-scrollbar-track:active {
  background: transparent;
}

::-webkit-scrollbar-corner {
  background: transparent;
}