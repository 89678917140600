body {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}

.mainContent {
  position: relative;
}

.loginBg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
  display: flex;
  > div {
    display: flex;
    flex-grow: 1;
    > img {
      object-fit: cover;
    }
  }
}

.loginBox {
  max-width: 300px;
  width: 100%;
  z-index: 3;
  position: absolute;
  background: $white;
  padding: $spacer-base*2.5;
  top: 30%;
  translate: 0 -20%;
  left: $spacer-base;
  right: $spacer-base;
  @media(min-width: $screen-sm) {
    left: 20%;
    top: 50%;
    right: initial;
    max-width: 380px;
    translate: 0 -50%;
  }
}

