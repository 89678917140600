.footer {
  padding-block: $spacer-base;
  @media(min-width: $screen-md) {
    padding-block: $spacer-base*2;
  }
  @media(min-width: $screen-lg) {
    padding-block: 25px;
  }
}

.footerBottom {
  > div {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;

    @media(min-width: $screen-sm) {

      gap: $spacer-base;
      justify-content: center;
      flex-direction: column;
      padding-left: 20px;
      padding-right: 20px;
      align-items: flex-start;
    }

    @media(min-width: $screen-md) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }
  &Right {
    ul {
        padding-bottom: 20px;
        padding-top: 20px;
      > li {
        padding-bottom: 16px;
        width: 35%;
        >a {
          font-size: 16px;
        }

        &:nth-child(2n) {
          padding-left: 100px
        }

        @media(min-width: $screen-xs) {
          padding-left: 20px;
          padding-right: 20px;
          width: 50%;
        }
        @media(min-width: $screen-md) {
          width: auto;
          padding-bottom: 0;

          &:nth-child(2n) {
            padding-left: 20px;

          }
        }
      }
      @media(min-width: $screen-md) {
        padding: 0;
      }
    }
  }
  &Left{
      order: 2;
      font-size: 12px;
    @media(min-width: $screen-md) {
      order: 0;
      font-size: 14px;
    }
  }
}
